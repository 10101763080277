@use "common/table-variables.scss" as *;
@use "table-fixed-column.scss" as *;

@use "selection/table-row-selection.scss" as *;//todo надо исправить цвета, пересекаются с цветами ячеек
@use "selection/table-selection.scss" as *;
@use "selection/table-multiple-selection.scss" as *;

@use "table-button.scss" as *;

@use "table-head.scss" as *;
@use "table-body.scss" as *;

@keyframes highlight {
  0% {
    background-color: rgba(33, 150, 243, 0.31);
  }
  100% {
    background-color: #fff;
  }
}

.alc-alphas-table {
  position: relative;
  //width: 100%;
  //height: 100%;
  outline: none !important;

  .alc-table-container {
    display: block;
    position:absolute;
    top:0;
    left:0;

    .alc-table {
      max-height: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      border-right: 1px solid $border-color;
      background-color: $background-color;

      &.alc-table-stripe-row {
        .alc-table-body {
          .alc-table-row:nth-child(odd){
            background-color: #FAFAFA;

            .alc-fixed-column.alc-table-td {
              background-color: #FAFAFA;
            }
          }
        }
      }
    }

    .alc-under-table-block {
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;

      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
    }
  }
}

.alc-cell__wrapper--overflow_icon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr .alc-row-tooltip {
  z-index: 10;
  display: none;
  padding: 14px 20px;
  margin-top: -30px;
  width: 300px;
}
tr:hover .alc-row-tooltip{
  display: inline;
  position: absolute;
  color: #f45e53;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
}

.alc-row-drop-line-below {
  .alc-table-td {
    position: relative;
  }
  .focusing-border-bottom {
    display: flex !important;
    height: 2px !important;
    background-color: #212121 !important;
  }
}

.alc-row-drop-line-above {
  .alc-table-td {
    position: relative;
  }
  .focusing-border-top {
    display: flex !important;
    height: 2px !important;
    background-color: #212121 !important;
  }
}

.cursor-grabbing {
  cursor: grabbing;
}
