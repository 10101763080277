.loading-svg{
  mask-image: conic-gradient(#FFFFFF00, #FFFFFF);
  animation: loader8Animation 1.5s linear infinite;
  @keyframes loader8Animation {
    0% {
      tranform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.loading-hide{
  display: none;
}
