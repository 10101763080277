@use "../components/Table/css/common/table-variables.scss" as *;

.app-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__container {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;

    .block-ui-container {
      z-index: 1310;
    }
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;
  }
  &__table {
    height: 100%;
    flex: 1 1 auto;
  }
  &__table-message{
    position: absolute;
    left: 50%;
    margin-top: 180px;
    margin-left: -50px;

    font-family: $font-family;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #212121;
    font-stretch: normal;

    &.sidebar-none {
      margin-left: 0px;
    }
  }

  &__page-not-found {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &__text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__not-found-message {
    display: flex;
    justify-content: center;
    margin-top: 180px;
    font-size: 40px;
  }

  &__loading {
    position: absolute;
    top: 50vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 0 !important;
    text-align: center;
  }
}

.table_list__row {
  height: 100%;
  width: 100%;
  display: table;

  .id_cell {
    max-width: 110px;
    min-width: 110px;
  }

  .languages_cell {
    max-width: 130px;
    min-width: 130px;
  }

  .name_cell {
    max-width: 135px;
    min-width: 135px;
  }

  .statistics_cell {
    max-width: 75px;
    min-width: 75px;
  }

  .status_cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .risks_cell {
    max-width: 55px;
    min-width: 55px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .rate_cell {
    max-width: 80px;
    min-width: 80px;
  }
  .timezone_cell {
    max-width: 112px;
    min-width: 112px;
  }
}

.status-emoji {
  font-size: 14px;
}

.translator-id {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  &__icon{
    margin-left: 6px;
  }
}

.table_list__cell {
  display: table-cell;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding-right: 7px;
  font-weight: 300;

  .icon-size {
    font-size: 13px;
    background-size: 15px;
  }
  .icon-height {
    height: 16px;
    width: 20px;
  }
}

.disable-value {
  color: #bdbdbd;
}

.wrapped-cell-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-description {
  &__container {
    padding-left: 14px;
  }

  &__text {
    color: #f44336;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
  }
}

.sidebar-helper-text {
  height: 16px;
}

.sidebar-overridden-button {
  margin-right: 12px;
}

.table_widget_row {
  &__container {
    display: flex;
    height: 50px;
    border-right: 1px solid #F5F5F5;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 0 7px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #424242;
    background-color: #F5F5F5;
  }
  &__fixed-row-selection {
    box-shadow: inset -1px 0px #E0E0E0;
    background-color: #FFFFFF;
    z-index: 3 !important;
    position: sticky;
  }
  &__numeric {
    display: flex;
    justify-content: flex-end;
  }

}

.add_comment_dialog {
  position: absolute !important;
  top: 30% !important;
  left: calc(47% - 375px / 2) !important;
  min-width: 375px !important;
}

.publish_quote_confirm {
  max-width: 420px !important;
  min-width: 420px !important;
}

.table-custom-loading {
  height: 100%;
  width: 100%;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-loader {
    margin-bottom: 100px;
  }
}

.cnd-customer-item {
  height: 65px !important;
}

.cnd-customer {
  display: table;
  height: 100%;
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;

  &_name, &_email {
    opacity: 0.5;
  }

  &__id-cell {
    max-width: 124px;
    min-width: 124px;
  }

  &__name-cell {
    max-width: 184px;
    min-width: 184px;
  }

  &__contacts-cell {
    max-width: 184px;
    min-width: 184px;
  }

  &_id, &_name, &_company-name, &_url, &_email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cnd-customer-cell {
  display: table-cell;
  //height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-weight: 300;
}

.cnd-customer-value {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  height: 100%;
  width: 100%;

  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px
}


.context-menu-table {
  height: 100%;
}

.context-menu-cell {
  position: relative;
  .alc-table-flex-container {
    max-height: none !important;
    height: 100% !important;
    .alc-table-td-content {
      padding: 0 !important;
      height: 100%;
    }
  }
}
