.modal-dialog {
  &__paper {
    min-width: 300px;
    margin: 48px !important;
  }
  &__title.MuiDialogTitle-root{
    padding: 24px 24px 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  &__container {}
  .close-btn{
    position: absolute;
    top: 22px;
    right: 18px;
  }
}