.publish_quote_description {
  font-size: 14px;
  color: #212121;

  max-width: 372px;
  word-break: break-word;
  white-space: pre-line;
}

.quote_confirm_button {
  color: #ffffff !important;
  background-color: #ff9800 !important;
  width: auto !important;
}

.quote_cancel_button {
  color: #212121 !important;
}

.confirm-button {
  background-color: transparent;
  color: #2196F3;
  text-transform: uppercase;
  box-shadow: none;
  width: 90px;
  height: 35px;

  &:hover {
    background-color: #F1F1F1;
  }
  &:focus {
    background-color: #bbc1e5;
  }
  &:active {
    box-shadow: none;
  }
}