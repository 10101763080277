@use "components/Table/css/common/table-variables.scss" as *;

@font-face {
    font-family: 'Roboto-300';
    font-style: normal;
    font-weight: 300;
    src: url('css/fonts/roboto-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('css/fonts/roboto-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('css/fonts/roboto-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('css/fonts/roboto-300.woff') format('woff'), /* Modern Browsers */
    url('css/fonts/roboto-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('css/fonts/roboto-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('css/fonts/roboto-400.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('css/fonts/roboto-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('css/fonts/roboto-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('css/fonts/roboto-400.woff') format('woff'), /* Modern Browsers */
    url('css/fonts/roboto-400.ttf') format('truetype'), /* Safari, Android, iOS */
    url('css/fonts/roboto-400.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('css/fonts/roboto-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('css/fonts/roboto-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('css/fonts/roboto-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('css/fonts/roboto-500.woff') format('woff'), /* Modern Browsers */
    url('css/fonts/roboto-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('css/fonts/roboto-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('css/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    body {
        margin: 0;
        padding: 0;
        font-family: Roboto, sans-serif;
    }
}

html, body, #root,
.App,
.App > .app-root,
.App > .app-root > .app-root__block {
    height: 100%;
}

.loading-container {
    margin-top: 64px;
    text-align: center;
}

.react-grid-Cell {
    color: #424242;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    border-right: none !important;
    border-bottom: none !important;

}

.react-grid-HeaderCell {
    color: #424242;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    border-right: none !important;
}

.react-grid-Main {
    outline: none !important;
    height: 100%;
}

.react-grid-Grid {
    border: none !important;
    min-height: 100% !important;
}

.react-grid-Canvas {
    height: 100% !important;
}

.react-grid-Container {
    height:  100%;
}

.sidebar-loading-container {
    margin-top: 20px;
}

.react-autocomplete-Results {
    max-height: none !important;
}

.block-ui-overlay {
    opacity: 0 !important;
}
