.prompt {
  &__dialog-container{
    width:434px;
  }
  &__input-container {
    //max-width: 252px;
    &.input-spacing{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  &__dialog-container-p{
    padding: 0 24px 24px !important;
    &.input-spacing{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  &__overflow{
    overflow-y: hidden !important;
  }
  &__m-0{
    margin: 0!important;
  }
  &__transfer-input-container, &__customer-input-container {
    display:block !important;
    width:100% !important;
    &.checkbox-container{
      padding-top: 8px;
    }
  }
  &__customer-dialog-container,
  &__diamonds-dialog-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width:528px;
    padding: 0 24px 24px !important;
    &.p-0{
      padding-top: 0!important;
    }
  }
  &__transfer-dialog-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width:528px;
    padding: 0 24px 20px !important;
    &.p-0{
      padding-top: 0!important;
    }
  }
  &__alpha-dashboard-dialog-container{
    width:528px;
    padding: 24px 24px 20px !important;
    .title{
      color: #212121;
      margin-bottom: 24px;

      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .close-btn{
      position: absolute;
      top: 22px;
      right: 20px;
    }
    .radio-group{
      gap: 16px;
    }
    .checkbox{
      &-group{
        gap: 16px;
      }
      &-label{
        line-height: 20px;
        &-container{
          margin: 0;
          align-items: flex-start;
        }
      }
      &-container{
        padding: 0 12px 0 0;
      }
    }
  }
  &__accept-button {
    box-shadow: none;
    width: 66px;
    padding: 6px 8px;

    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background-color: #e8e8e8;
    }
    &:active {
      box-shadow: none;
    }
  }
  &__cancel-button {
    background-color: #ffffff;
    color: #2196F3;
    border: 1px solid #e8e8e8;
    box-shadow: none;
    width: 95px;
    padding: 6px 8px;

    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background-color: #ffffff;
    }
    &:active {
      box-shadow: none;
    }
  }
}

.large-width {
  width: 400px !important;
}
