.limit-cell {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__value {
    overflow: hidden;
  }
  &__limit-title {
    padding-top: 5px;
    color: #bdbdbd;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }
}