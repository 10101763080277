.checkpoint_info {
  background: #FFF6E8;
  margin-bottom: 8px;
}

.checkpoint_info_warning {
  background: #FFE0B2;
}

.checkpoint-table {
  &__container {
    margin-top: 8px;
  }

  &__item-row {
    display: flex;
    gap: 8px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &.title{
      margin-bottom: 0;
    }
  }

  &__item {
    display: block;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.first {
      width: 47px;
    }
    &.second {
      width: 48px;
    }
    &.third {
      width: 36px;
    }
    &.fourth {
      width: 36px;
      text-align: right;
    }
    &.bold{
      font-weight: 500;
    }
  }
}
