@use "../../../components/Table/css/common/table-variables.scss" as *;

.header {
  color: #fff;
  position: static;
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  &__apples {}
  &__search {
    display: flex;
  }

  &__items {
    display: flex;
    position: relative;
    align-items: center;
  }
  .right-menu {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    z-index: 1400;
  }
  &__logo {
    font-family: $font-family;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: inherit;

    position: absolute;
    width: 100%;
    z-index: 0;
    margin: 0;
    display: block;
  }
  &__emoji {
    display: inline-flex;
    padding: 0px 5px 0px 5px;
  }
}

header {
  background-color: #212121 !important;
  height: 48px;
  min-height: 48px !important;
  box-shadow: none !important;

  > div {
    display: flex;
    justify-content: space-between;
    min-height: 48px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
