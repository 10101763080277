.leads-date-range {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 13px !important;
}

.DateRangePickerInput {
  min-height: 36px;
  position: sticky;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.1875em;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;


  .DateInput_input {
    padding: 9px 2px 9px 12px;
    font-size: 14px;
  }
}

.DateRangePicker_popper-root {
  z-index: 10000 !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}