@use "common/table-variables.scss" as *;
@use "selection/table-selection.scss" as *;
@use "selection/table-multiple-selection.scss" as *;

.alc-alphas-table {
  .alc-table-container {
    .alc-table {


      .alc-table-head {
        .alc-table-row {
          &.alc-table-row-column-selected {
            .alc-fixed-column {
              &.alc-fixed-row-selection {
                left: $fixed-row-selection-left;
              }
              &.alc-fixed-custom {
                left: 29px;
              }
            }
          }

          .alc-fixed-column {
            &.alc-fixed-custom {
              left: 0;
            }
          }
        }
      }

      .alc-table-body {
        .alc-table-row {
          &.alc-table-row-column-selected {
            .alc-fixed-column {
              &.alc-fixed-row-selection {
                left: $fixed-row-selection-left;
              }
              &.alc-fixed-custom {
                left: $fixed-custom-left;
              }
            }
          }

          .alc-fixed-column {
            &.alc-fixed-custom {
              left: $fixed-row-selection-left;
            }
          }
        }
      }

      .alc-fixed-column {
        position: sticky;

        &.alc-fixed-row-selection {
          //left: $fixed-row-selection-left;
        }

        &.alc-fixed-custom {
          //left: $fixed-custom-left;

          &.alc-table-th:after {
            background-color: #fafafa;
          }

          &.alc-table-td:after {
            background-color: #ffffff;
          }
        }

        &.alc-table-th {
          z-index: 5 !important;
        }

        &.alc-table-td {
          box-shadow: inset -1px 0px $border-color;
          background-color: $background-color;
          z-index: 3 !important;
        }
      }
    }
  }
}
