@use "./style-variables" as *;

.customer-dashboard__button {
  width: $columns-button-size !important;
  height: $columns-button-size !important;
  min-width: $columns-button-size !important;
  min-height: $columns-button-size !important;
}

.customer-dashboard__refresh-button {
  width: $columns-button-size !important;
  height: $columns-button-size !important;
  margin-right: 7px;
}

.customer-dashboard__download-button {
  margin-left: 0 !important;
  background: url(img/svg/more_vert.svg) center center / 20px no-repeat;
}

.customer-dashboard__date_range-button {
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon.svg) center center / 16px no-repeat;
}

.customer-dashboard__date_range-button-blue {
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon-blue.svg) center center / 16px no-repeat;
}

.customer-dashboard__project-ids-button {
  display: flex;
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon.svg) center center / 16px no-repeat;
}

.customer-dashboard__project-ids-button-blue {
  display: flex;
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon-blue.svg) center center / 16px no-repeat;
}

.customer-dashboard__services-button {
  display: flex;
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon.svg) center center / 16px no-repeat;
}

.customer-dashboard__services-button-blue {
  display: flex;
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon-blue.svg) center center / 16px no-repeat;
}

.customer-dashboard__show-delivered-button {
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon.svg) center center / 16px no-repeat;
}

.customer-dashboard__show-delivered-button-blue {
  margin-left: 2px !important;
  background: url(img/svg/dashboard-filter-icon-blue.svg) center center / 16px no-repeat;
}

.dashboard-quotes-link {
  position: relative;

  &:hover {
    &:after {
      content: '';
      position: absolute;
      height: 15px;
      width: 15px;
      left: 100%;
      margin-left: 8px;
      background: url(img/svg/quotes-launch.svg) center center / 14px no-repeat;
    }
  }
}

.dashboard__container {
  background-color: $design-gray-40;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //align-items: stretch;
  //width: 100%;
  //height: 100%;

  .dashboard-table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 40px;
    padding-bottom: 16px;

    &__title {
      padding: 16px 0;
      color: $design-black;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      &-container {
        display: flex;
        gap: 24px;
      }
    }

    &__container {
      //border: 1px solid #E0E0E0;
      box-shadow: 0 0 7px 0 rgba(0,0,0,0.08);
      background-color: $design-white;
      width: 0;
      //height: 194px;

      .alc-table {
        border-right: none !important;
      }

    }

    .alphas-table__container {
      //height: 194px;
      //width: 988px;
    }
    .quotes-table__container {
      //height: 194px;
      //width: 639px;
    }
    .invoices-table__container {
      //height: 233px;
      //width: 1232px;
    }

  }
}

.dashboard-loader-row {
  .group-row-custom__cell-fixed {
    box-shadow: none !important;
  }
}


.dashboard__sidebar {
  width: 208px !important;
  min-width: 208px !important;
  background-color: $design-white;
  .sidebar__container {
    margin-right: 0 !important;

    .sidebar__container_top {
      padding-top: 0 !important;
    }
  }
}
.bill_to_container{
  margin-top: 16px;
}

.dashboard-widget {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
  background-color: $design-dashboard-blue;

  .bill_to_info {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
  }

  .debt_info {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .debt_value {
    font-weight: 500;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dashboard_pay_button {
  position: absolute;
  right: 0;
  width: 47px;
  height: 24px;
  min-height: 24px;

  background-color: $design-blue;

  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.12);
  padding: 10px 12px;

  border-radius: 4px;

  color: $design-white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  &:hover {
    background-color: $design-blue-hover !important;
  }
}

.scrollable__filtered {
  .alphas-item {
    .scrollable__menu-item-adornment {
      color: $design-blue;
    }
  }
}
