@use "../../../components/Table/css/common/table-variables.scss" as *;

.alpha-checkbox-root {
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.alpha-checkbox-checked {
  color: #1f87e3 !important;
}

.alpha-checkbox-label {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 12px;
}
.container {
  display: block;
  position: relative;
  padding-left: 18px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 18px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid black;
  opacity: 0.5;
  border-radius: 2px;
  background-color: transparent;
}

.checkmark-disable {
  border: 2px solid #bdbdbd;
  cursor: default;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: none;
  opacity: 1;
  background-color: #1f87e3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-container-checkbox {
  display: flex;
  justify-content: center;
}

.display-none {
  display: none !important;
}