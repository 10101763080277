@use "common/table-variables.scss" as table;

.alc-table-body {
  .alc-table-row {

    &-cell-border {
      .alc-table-cell {
        box-shadow: -1px 1px 0px 0px #e0e0e0 inset !important;
      }
      &:first-child {
        .alc-table-cell {
          box-shadow: -1px 0px 0px 0px #e0e0e0 inset !important;
        }
      }
    }

    &-collapsed {
      visibility: collapse !important;
      //display: none !important;
    }

    .alc-row-tooltip-cell {
      padding: 0;
    }

    .alc-table-td {
      padding: 0 !important;
      max-width: 400px;
      outline: none;
      vertical-align: top;
      color: table.$td-color;

      &.height-by-text {
        .alc-table-flex-container {
          height: auto;
          max-height: 100%;
        }

        .alc-table-td-content {
          padding: 8px;
          word-wrap: break-word;
          white-space: pre-wrap !important;
        }
      }

      &.nowrap-text {
        .alc-table-td-content {
          white-space: nowrap;
        }
      }

      //todo привет, я костыль! я здесь потому что material ui Input не может нормально посчитать height при multiline=true
      //todo костыль не живой, textarea height: 100% не даёт полю расшырятся по тексту при печати, переиначить
      &:not(.height-by-text) {
        .alc-input-container {
          .alc-textarea-input {
            > div {
              > textarea {
                //height: 100% !important;
              }
            }
          }
        }
      }

      .alc-table-flex-container { //todo переименовать в alc-table-td-content
        display: flex;
        align-items: center;
        height: 32px;
        max-height: 32px;
        //height: 100%;
        .alc-table-flex-container-tooltip{
          font-weight: 500;
        }
      }

      .alc-table-td-content { //todo переименовать в alc-table-td-value
        align-self: flex-start;
        padding: 8px;

        //display: inline;
        //position: relative;
        width: 100%;
        min-height: table.$row-height;
        //padding: 5px 7px 4px;
        align-items: center;
        overflow: hidden;
        white-space: pre;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 16px;
        //line-height: 1.1875em;
        //line-height: $row-line-height;
        letter-spacing: normal;
        text-align: left;
        outline: none;
        text-overflow: ellipsis;

        //word-wrap: break-word;
        //white-space: normal;


        .alc-table-cell-placeholder {
          opacity: 0.5;
          color: #424242;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
        }
      }

      .alc-table-cell-reverse {
        display: flex;
        flex-direction: row-reverse;
      }

      .alc-table-cell-with-checkbox {
        padding-right: 4px !important;
      }

      .alc-table-cell-with-checkbox-display {
        visibility: visible !important;
      }

      &.border-left {
        box-shadow: inset 1px 0px #E0E0E0;
      }

      &.border-right {
        box-shadow: inset -1px 0px #E0E0E0;
      }

      &.vertical-align-top {
        vertical-align: top;
      }

      &.date-type {
        .alc-table-td-content {
          text-overflow: clip !important;
        }
      }

      &.number-type {
        .alc-table-td-content {
          justify-content: flex-end;
          text-align: right;
        }

        .alc-input-copy {
          text-align: right !important;
          padding-right: table.$cell-padding;
        }
      }

      //empty cell values styles
      //todo перенести пустой цвет по умолчанию в css файлы
      &.alc-cell-empty {
        &.alc-table-cell {
          background-color: table.$empty-cell-red;

          &.cell-multiple-selected {
            //opacity: 0.6 !important;
          }

          &.number-type {
            .alc-table-td-content {
              color: table.$background-color;
            }
          }
        }

        &.alc-table-cell-edit {
          &.alc-cell-copy {
            background-color: table.$empty-cell-red;

            .alc-input-copy {
              background-color: table.$empty-cell-red;
              color: table.$background-color;
              text-align: right !important;
            }

            &.number-type {
              .alc-input-copy {

                padding-right: table.$cell-padding;
              }
            }
          }
        }
      }

      &.cell-selected:not(.cell-link-checkbox) {
        @include table.selection-cell;

        .number-type {
          justify-content: flex-end;
          padding-right: table.$cell-padding !important;
        }
      }

      &.cell-disabled {
        background: #F5F5F5 !important;
        color: #999999 !important;
      }

      &:focus {
        outline-color: transparent;
      }

      .alc-input-container {
        .alc-input {
          //padding-left: $cell-padding;
        }
      }

      .alc-table-td-btn-container {
        display: flex;
        gap: 8px;
        align-items: center;
        padding-right: 7px;
      }

      &:first-child {
        .alc-table-td-content {
          padding: 8px 8px 8px 16px;
        }
      }
    }

    &.alc-table-tooltip {
      .alc-table-td-content {
        position: relative !important;
      }
    }
  }

  .alc-table-row-changed {
    animation: highlight 1000ms ease-out;

    .alc-fixed-column.alc-table-td {
      animation: highlight 1000ms ease-out;
    }
  }
}
