.customer_stat_info {
  background: rgb(244, 244, 244);
  margin-bottom: 15px;
}

.customer_stat_value {
  left: 100px;
}

.customer_stat_title {
  max-width: 85px;
}

.customer-stat-table {
  &__container {
    width: 100%;
  }

  &__item {
    font-weight: 400;
    height: 21px;
    text-align: left;

    &.created {
      width: 70px;
      min-width: 70px;
    }
    &.communication {
      min-width: 40px;
    }
    &.process {
      min-width: 40px;
    }
    &.success {
      min-width: 40px;
    }
  }
}