aside.EmojiPickerReact.epr-main{
  border-radius: 3px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);

  .epr-header{
    display: flex;
    flex-direction: column-reverse;

    .epr-category-nav{
      padding: 8px 12px;
      button.epr-cat-btn{
        background-image: url("../../../css/img/svg/emoji/Emoji-sections.svg");
        background-position-y: -18px;
        width: 28px;
        height: 28px;
        background-size: 340px;

        &:hover{
          background-position-y: -51.5px;
        }

        &:focus:before{
          border: 0;
        }

        &.epr-active{
          background-position-y: -85px;
        }

        &.epr-icn-suggested{
          background-position-x: -18px;
        }
        &.epr-icn-smileys_people{
          background-position-x: -51.5px;
        }
        &.epr-icn-animals_nature{
          background-position-x: -85px;
        }
        &.epr-icn-food_drink{
          background-position-x: -118.5px;
        }
        &.epr-icn-activities{
          background-position-x: -152px;
        }
        &.epr-icn-travel_places{
          background-position-x: -185.5px;
        }
        &.epr-icn-objects{
          background-position-x: -218.5px;
        }
        &.epr-icn-symbols{
          background-position-x: -252px;
        }
        &.epr-icn-flags{
          background-position-x: 54px;
        }
      }
    }

    .epr-header-overlay{
      border-top: 1px solid rgba(214, 214, 214, 1);
      border-bottom: 1px solid rgba(214, 214, 214, 1);
      padding: 8px 12px;

      .epr-search-container{

        .epr-search{
          padding: 8px 8px 8px 0px;
          font-size: 14px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color:#BDBDBD;
          background-color: transparent;
          border: none;
        }

        .epr-icn-search{
          width: 28px;
          height: 28px;
          background-size: 28px;
          left: unset;
          right: 0;
          background-image: url("../../../css/img/svg/input-search.svg");
        }

        .epr-btn-clear-search{
          right: 25px;
        }
      }
    }
  }

  .epr-emoji-list{

    .epr-emoji-category{

      .epr-emoji-category-label{
        color: #323232;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        padding: 8px 12px 4px;
      }

      .epr-emoji-category-content{
        grid-template-columns: repeat(auto-fill, 28px);
        gap: 8px;
        margin: 8px 12px;

        button.epr-emoji{
          width: 28px;
          height: 28px;
          max-width: 28px;
          max-height: 28px;
          border-radius: 4px;

          .epr-emoji-img{
            max-width: 28px;
            max-height: 28px;
            min-width: 28px;
            min-height: 28px;
            padding: 3px;
          }
        }
      }
    }
  }
}