.DayPicker {
  display: inline-block;
  width: 242px !important;
  box-shadow: none !important;
  border-radius: 0 !important;

  > div > div {
    width: 242px !important;
  }

  .DayPicker_transitionContainer {
    width: 242px !important;
    margin-bottom: -20px;

    .CalendarMonthGrid {
      left: 0 !important;

      .CalendarMonthGrid_month__horizontal {
        width: 242px !important;
        margin-bottom: -23px;
      }
    }
  }

  .DayPickerNavigation_container {

    .DayPickerNavigation_leftButton__horizontal {
      left: 11px !important;
    }

    .DayPickerNavigation_rightButton__horizontal {
      right: 11px !important;
    }

    .DayPickerNavigation_button {
      padding: 5px !important;
      top: 9px !important;
      border-radius: 4px !important;
      border: none;

      &:hover {
        background-color: #E0E0E0;

        .DayPickerNavigation_svg__horizontal {
          fill: #424242;
        }
      }

      &:focus {
        outline: none !important;
        outline-color: transparent;
      }
    }
  }

  .DayPicker_weekHeaders {
    margin-left: 0 !important;

    .DayPicker_weekHeader {
      top: 47px !important;
      border-top: 1px solid #E0E0E0;
      width: 242px !important;
      padding: 0 16px !important;
      padding-top: 8px !important;

      .DayPicker_weekHeader_ul {
        margin: 0 !important;

        .DayPicker_weekHeader_li {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 30px !important;
          width: 30px !important;
        }
      }
    }
  }

  .CalendarMonth {
    padding: 0 16px !important;

    .CalendarMonth_caption {
      padding-top: 16px !important;
    }
    .CalendarMonth_caption {
      padding-bottom: 55px !important;
      color: #212121;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
    }
  }
}
