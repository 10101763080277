.upload{
  &-container{
    border-radius: 4px;
    border: 1px dashed #BDBDBD;
    margin: 0 0 8px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-link{
      display: inline-flex;
      cursor: pointer;
      &__context{
        font-size: 14px;
        line-height: 20px;
        color: #757575;
      }
      .icon_attach-files{
        width: 20px;
        -webkit-mask-image: url("../../../../../css/img/svg/attach-files_ico.svg");
        background: #757575;
        margin-right: 4px;
      }
    }

    &.error {
      border-color: #f44336;

      .upload-container-link {
        &__context {
          color: #f44336;
        }
        .icon_attach-files{
          background: #f44336;
        }
      }
    }
  }
}