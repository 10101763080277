.alc-input-container {
  height: 100%;
  position: relative;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;

  .alc-textarea-input {
    align-self: flex-start;
    height: 100%;
    padding-top: 9px !important;
    padding-bottom: 7px !important;
    padding-left: 7px;
    padding-right: 7px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    align-items: flex-start;

    > div {
      height: 100%;
      > textarea {
        //height: 100%;
      }
    }
  }
}

.alc-textarea-menu {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;

  padding: 5px 0;
  //margin-top: 4px;

  min-width: 180px;
  max-width: 180px;
  max-height: 350px;
  //width: 150px;
  //height: 250px;
  //background-color: #66e2da;

  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.08);

  &:focus {
    outline-color: transparent;
  }

}

.alc-textarea-menu-item {
  display: flex;
  align-items: center;
  padding: 0 14px;

  min-height: 32px;

  /*&:hover {
    background-color: #D4EBFD;
  }*/
}

.alc-textarea-menu-item-name {
  color: #424242;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}

.alc-textarea-menu-item-selected {
  background-color: #D4EBFD;
}
