@use "../../../css/style-variables" as *;

.range-wrapper{
  &__title-wrapper{
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    &__title{
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      display: flex;
      gap: 8px;
    }
    &__buttons{
      display: flex;
      gap: 8px;
      .button-clear-delete{
        color: $design-gray-90;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        &:hover, &:focus{
          color: $design-blue-hover;
        }
        &:focus-visible{
          outline: none;
        }
      }
    }
  }
  &__inputs{
    display: flex;
    justify-content: space-between;
    position: relative;
    &-separator {
      width: 8px;
      height: 1px;
      background-color: $design-gray-90;
      margin-top: 18px;
    }
  }
  &__input-wrapper{
    box-sizing: content-box !important;
    height: 36px;
    width: 100px !important;
    border: 1px solid $design-gray-70;
    border-radius: 4px;
  }
  &__input{
    padding: 10px 11px 10px 10px !important;
    &-error:not(:focus-within) {
      border: 1px solid $design-red !important;
      margin-bottom: 12px;

      &:after {
        content: 'Invalid value';
        position: absolute;
        height: 16px;
        color: $design-red;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;
        top: calc(100% + 4px);
        display: flex;
        align-items: center;
      }
    }
    &-focused{
      border: 1px solid $design-blue;
    }
  }
  &__radio{
    width: 20px;
    height: 20px;
    border: 1px solid $design-gray-70;
    background: transparent;
    border-radius: 50%;
    position: relative;
    &:hover{
      border-color: $design-blue-hover;
    }
    &.active{
      border-color: $design-blue;
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        background-color: $design-blue;
      }
    }
  }
}