.panel-wrapper{
  padding: 12px 16px;
  margin-bottom: 8px;
  background: #f4f4f4;
  cursor: pointer;

  &:last-child{
    margin-bottom: 0;
  }

  &__header{
    display: flex;
    justify-content: space-between;

    &__title{
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500;
    }
  }
}