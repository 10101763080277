.search-input {
  &__container {
    display: flex;
    height: 44px;
    border-bottom: 1px solid #D6D6D6;
    padding: 8px 8px 8px 12px;
    margin-bottom: 8px;
  }
  &__input {
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
    outline: none;

    font-size: 14px;
    line-height: 16px;

    &::placeholder {
      color: #BDBDBD;
    }
  }
  &__icon{
    cursor: pointer;
    color:#323232;
    min-width: 28px;
    min-height: 28px;
    border-radius: 4px;
    &:hover{
      background-color: #F5F5F5;
    }
  }
}