@use "../common/table-variables.scss" as *;

.alc-alphas-table {

  .alc-table-container {

    .alc-table {

      .alc-table-body {

        .alc-table-row-multiple-selected:not(.alc-fixed-column) {

          .cell-multiple-selected:not(.alc-fixed-column) {
            position: relative;
          }

          .focusing-top {
            .focusing-border-top {
              display: flex;
            }
          }
          .focusing-right {
            .focusing-border-right {
              display: flex;
            }
          }
          .focusing-bottom {
            .focusing-border-bottom {
              display: flex;
            }
          }
          .focusing-left {
            .focusing-border-left {
              display: flex;
            }
          }
        }

        .alc-table-row {
          .focusing-border-top {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $cell-focus-color;
            height: 1px;
          }
          .focusing-border-right {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: $cell-focus-color;
            width: 1px;

          }
          .focusing-border-bottom {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $cell-focus-color;
            height: 1px;
          }
          .focusing-border-left {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background-color: $cell-focus-color;
            width: 1px;
          }

          .alc-table-td {
            &.cell-multiple-selected {
              background-color: $cell-focusing-color;
              //color: $th-color;
              //outline: $cell-focus-color solid 2px !important;
              //outline-offset: -1.5px;

              .alc-table-td-content {
                //background-color: $cell-focusing-color !important;
                //color: $th-color;
              }
            }
          }

        }
      }
    }
  }
}