@use "../../../../../components/Table/css/common/table-variables.scss" as *;

.translator_info {
  background: rgb(237, 231, 246);
  //padding-bottom: 5px;
}

.email_info {
  //margin: 1px 0 1px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;

  &__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.name_info {
  //margin-top: 7px;
  //margin-bottom: 2px;
}

.status_info {
  //margin-top: 7px;
}

.vacations_info{
  height: 34px;
  &__value{
    max-height: 34px;
  }
}

.delivered_info {
  &__title {}
  &__value {}
}

.icon-container {
  display: flex;
  justify-content: flex-start;
}

.work-info__fix-value {
  overflow: hidden;
  max-width: 85px;
  max-height: 15px;
  word-wrap: normal;
  text-overflow: ellipsis;
}

.work-info__weak-exp {
  color: #F44336 !important;
}

.language-info {
  //margin-bottom: 5px;
}

.info-panel__center-value{
  font-weight: 500;
  position: absolute;
  left: 95px;
  right: 0;
}

.comments-button {
  min-width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 4px;
}

.comments-icon {
  background: url(../../../../../css/img/svg/comment_rate_icon.svg) center center / 17px no-repeat;
}

.rate-icon {
  background: url(../../../../../css/img/svg/star-24px.svg) center center / 20px no-repeat;
}

.wiki-icon {
  background: url(../../../../../css/img/svg/wiki.svg) center center / 20px no-repeat;
}

.google-drive-icon {
  background: url(../../../../../css/img/svg/google-drive-icon.svg) center center / 18px no-repeat;
}

.comments_info {
  //padding: 3px 0;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  //cursor: pointer;

  /*&:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }*/
}

.comments_loading {
  margin-top: 2px;
  margin-bottom: 1px;
  align-self: center;
}

.translator_comments_link {
  margin-top: 8px;
}

.comments_link {
  display: block;
  //padding: 5px 0 5px 0;
  text-decoration: none;
  color: #2196F3;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border-radius: 0.3px;

  &:hover {
    text-decoration: underline;
  }
}

.rates_link {
  display: block;
  //padding: 5px 0 5px 0;
  text-decoration: none;
  color: #2196F3;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border-radius: 0.3px;
  margin: 4px 0 8px 0;

  &:hover {
    text-decoration: underline;
  }
}

.comment {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  &.success-comment{
    margin-top: 8px;
  }

  &_text {
    color: rgba(50, 50, 50, 1);
    word-wrap: break-word !important;
    white-space: normal !important;
  }
  &_caption {
    color:rgba(189, 189, 189, 1);
  }
  &_date {}

  &_empty {
    color: rgba(189, 189, 189, 1);
  }
}

.response_info {
  margin-bottom: 8px;
}

.statistics {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  background-color: rgba(232, 232, 232, 1);
  margin-top: 8px;
  border-radius: 2px;
  cursor: pointer;

  &__icon {
    padding: 7px 8px;

  }

  &__value-wrapper {
    min-width: 0
  }

  &__value {
    color: #424242;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    padding: 0 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.comment_statistics {
}

.tqi_statistics {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.tqi_statistics-value {
  padding: 0 4px;
}

.comments-margin{
  margin-right: 8px;
}

.comment_statistics_icon {
  background: url(../../../../../css/img/svg/comment_statistics_icon.svg) center center / 8px no-repeat;
}

.rate_statistics_icon {
  background: url(../../../../../css/img/svg/rate_statistics_icon.svg) center center / 10px no-repeat;
}

.task-statistics_icon {
  background: url(../../../../../css/img/svg/task.svg) center center / 8px no-repeat;
}
