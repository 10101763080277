@use "../../../css/style-variables" as *;

.refresh-button {
  cursor: pointer;
  padding: 0;
  background: transparent;
  box-shadow: none;
  margin: 0;
  width: unset;
  height: unset;
  min-width: unset;
  min-height: unset;
  &:hover{
    background: transparent;
    .icon_refresh{
      color: $design-blue-hover;
    }
  }
}

.refresh-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}