.total-elements {
  &__divider{
    margin-bottom: 16px;
  }
  .label{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: right;
    color:#323232;
  }
}