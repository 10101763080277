@use "./style-variables" as *;

.alc-input-phone {
  height: 46px !important;
  width: 100% !important;
  padding: 12px 0 12px 54px !important;
  background-color: $design-white !important;

  border-radius: 5px !important;
  border: 1px solid $design-gray-70 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 20px !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: $design-gray-90 !important;
}

.alc-phone-button {
  border: none !important;
  width: 45px;

  .selected-flag {
    background-color: $design-white !important;
    border-radius: 5px 0 0 5px !important;
    border: 1px solid $design-gray-70;
    border-right: 1px solid $design-gray-70 !important;
    width: auto !important;

    .flag .arrow{
      left: 25px;
    }
  }
}

.alc-dropdown-phone-button {

}

.react-tel-input {
  font-family: 'Roboto',sans-serif;
  font-size: 16px;
  position: relative;
  width: 100%;
}

.alc-input-phone-container {
  position: relative;
  width: 100%;

}

.alc-input-phone-label {
  position: absolute;
  z-index: 3;
  font-family: Roboto, sans-serif !important;
  font-size: 10px !important;
  line-height: 1.25 !important;
  font-weight: 400;
  margin-top: -6px;
  margin-left: 12px;
  background: $design-white;
  color: $design-gray-70;
}

.alc-phone-container {
  margin-top: 8px;
  margin-bottom: 4px;
}
.phone-red-border .alc-input-phone{
  border-color: $design-red !important;
}
.phone-red-border .selected-flag{
  border-color: $design-red !important;
  border-right: 1px solid $design-gray-70 !important;
}
.alc-phone-container:focus-within .alc-input-phone-label{
  color: $design-blue;
}
.alc-phone-container:focus-within .alc-input-phone{
  border-color: $design-blue !important;
}
.alc-phone-container:focus-within .selected-flag{
  border-color: $design-blue !important;
  border-right: 1px solid $design-gray-70 !important;
}

.alc-input-helper-text {
  display: block;
  margin: 8px 12px 0;
  font-size: 0.75rem;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.6);
}
