.alc-input-container {
  height: 100%;
  position: relative;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;

  .alc-textarea-input {
    align-self: flex-start;
    height: 100%;
    padding-top: 9px !important;
    padding-bottom: 7px !important;
    padding-left: 7px;
    padding-right: 7px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;

    > div {
      height: 100%;
    }
  }
}

.alc-textarea-menu {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;

  padding: 5px 0;

  min-width: 180px;
  max-width: 180px;
  max-height: 350px;

  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.08);

  &:focus {
    outline-color: transparent;
  }

}

.alc-textarea-menu-item {
  display: flex;
  align-items: center;
  padding: 0 14px;

  min-height: 32px;
}

.alc-textarea-menu-item-name {
  color: #424242;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}

.alc-textarea-menu-item-selected {
  background-color: #D4EBFD;
}

.alpha-filter-tags {

  &__tags {
    max-width: 133px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__adornment {
    display: flex;
    align-items: center;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 36px;
    padding-left: 12px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    cursor: text;

    .tag {
      display: inline-block;
      height: 19px;
      padding: 0 6px 0 6px;
      border-radius: 15px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1875em;
      letter-spacing: normal;
      white-space: nowrap;

      margin-top: 2px;
      margin-bottom: 2px;

      user-select: none;

      &:not(:last-child):not(:only-child) {
        margin-right: 4px;
      }
    }

    .tag-placeholder {
      color: #bebebe;
      font-size: 14px !important;
      text-overflow: ellipsis !important;
      position: absolute;
      top: 8px;
    }
    .tag-placeholder-values {
      color: #bebebe;
      font-size: 10px !important;
      line-height: 1.172 !important;
      text-overflow: ellipsis !important;
      position: absolute;
      top: -7px;
      background-color: #fff;
    }
  }

  &__button {
    position: relative;
    right: 4px;
    padding: 0 !important;
    width: 16px !important;
    height: 16px !important;
    justify-self: flex-end;

    &:hover{
      background: unset;
    }

    > button {
      position: relative;
      right: 32px;
      padding: 0 !important;
      min-width: 16px;
      min-height: 16px;
    }
  }
}
