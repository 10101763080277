@use "../../../css/style-variables" as *;

.step-buttons{
  display: flex;
  .today-button {
    background-color: transparent;
    box-shadow: none;
    color: #505050;
    padding: 0;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    &:hover {
      background-color: transparent;
    }
  }
  .today-title {
    color: $design-blue;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0 8px;
    width: auto;
    height: auto;
    min-height: unset;
  }
  .arrow{
    color: $design-gray-90;
    cursor: pointer;

    &-button{
      border: 0;
      background: transparent;
      padding: 0;
      display: flex;
    }
    &.right{
      transform:rotate(180deg);
    }
    &.disabled{
      color: $design-gray-70;
      cursor: default;
    }
    &:hover:not(.disabled){
      color: $design-blue-hover;
    }
  }
}