.payments-statistics {
  &__title{
    margin-top: 8px;
    .info-row .cell.right {
      font-weight: 400;
    }
  }
  &__right{
    text-align: right;
  }
}
