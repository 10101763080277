@use "../../../css/common/table-variables.scss" as *;

.alc-textarea-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;

  .alc-textarea {
    flex: 1 1 auto;
    align-self: stretch;

    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    outline: none !important;
    border: none !important;

    white-space: nowrap;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    //color: #424242;
    color: transparent !important;
    resize: none;
    overflow: hidden;
  }

  .alc-textarea-copy {
    caret-color: transparent !important;

    &::selection {
      background: transparent;
    }

    &::-moz-selection {
      background: transparent;
    }
  }

  .alc-number-input {
    text-align: right !important;
    padding-right: $cell-padding;
  }
}