$arrow-icon-size: 9px;
$icon-size: 11px;

.sorting-title__hovering {
  .sorting-title__icon {
    display: none !important;
  }

  .sorting-title__title:before {
    display: none;
    content: '';
    position: absolute;
    top: 1px;
    left: -14px;
    min-height: 11px;
    min-width: 11px;
    background: url(../../../../css/img/svg/sort/sorting-arrow-down.svg) center center / $arrow-icon-size no-repeat;
  }

  &:hover {
    .sorting-title__title:before {
      display: block !important;
    }
  }

  &.sorting-title__sorted {
    .sorting-title__title:before {
      display: block !important;
    }
  }

  &.sorting-title__sorted-asc {
    .sorting-title__title:before {
      background: url(../../../../css/img/svg/sort/sorting-arrow-up.svg) center center / $arrow-icon-size no-repeat !important;
    }
  }

  &.sorting-title__sorted-desc {
    .sorting-title__title:before {
      background: url(../../../../css/img/svg/sort/sorting-arrow-down.svg) center center / $arrow-icon-size no-repeat;
    }
  }
}
