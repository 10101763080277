@use '../../../css/common/table-variables.scss' as *;

.autocomplete-menu {
  min-width: 140px;
  border-radius: 3px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  background-color: #fff;
  //padding: 8px 0;
  margin-top: 4px;
  //font-size: 90%;
  position: fixed;
  overflow: initial;
  //max-height: 350px;
  z-index: 11;

  .autocomplete-container {
    display: flex;
  }

  .autocomplete-info-panel {
    width: 194px;
    position: fixed;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }

  .autocomplete_items {
    background-color: #fff;
    //padding: 8px 0;
    font-size: 90%;
    min-width: 140px;
    max-height: 350px;
  }

  .autocomplete_buttons {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid #E0E0E0;

    .autocomplete_button {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-transform: none;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 28px;
      min-width: 28px;
      margin: 3px 0;
      width: 66px;
    }
    .save_button {
      margin-right: 8px !important;
    }

    .cancel_button {
      margin-left: 10px;
    }

  }

  .hovered {
    background-color: #f4f4f4 !important;
  }

  .selected {
    font-weight: 500 !important;
  }

  .autocomplete-item {
    height: 30px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    cursor: pointer;
    background-color: transparent;

    /*&:hover {
      background-color: #f4f4f4 !important;
    }*/

    &_default-cursor {
      cursor: default;
    }

    .autocomplete-item-select {
        padding: 8px !important;
    }

    .autocomplete-item-label {
      //display: flex;
      align-items: center;
      width: 100%;
      //height: 100%;
      font-family: $font-family;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #424242;
      padding-left: $base-padding;
      padding-right: 10px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.autocomplete {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;

  &:focus {
    outline: none;
  }

  .autocomplete-input {
    flex: 1 1 auto;
    align-self: stretch;

    width: 100%;
    padding-top: 9px !important;
    padding-bottom: 6px !important;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    align-items: center;
    outline: none !important;
    border: none !important;
    //border: 2px solid #66afe9;
    //outline: 2px solid #66afe9;
    //padding-left: $cell-padding;

    white-space: nowrap;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #424242;
    height: 17px;

    &:focus {
      //border: none !important;
      //outline: 2px solid #66afe9;
    }

  }
}
