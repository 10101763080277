@use "../../../css/common/table-variables.scss" as *;

.SingleDatePicker {
  background-color: transparent !important;
  position: relative;
  display: inline-block;
  width: 100%;

  .DateInput_fang {
    display: none;
  }

  .SingleDatePickerInput {
    background-color: transparent !important;
    width: 100%;

    .DateInput_input {
      width: 100%;
      padding-top: 8px !important;
      padding-bottom: 6px !important;
      padding-left: 7px;
      padding-right: 7px;
      display: flex;
      align-items: center;
      outline: none !important;
      border: none !important;
      //padding: 0 0 0 $cell-padding;

      white-space: nowrap;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: left;
      color: #424242;
      height: 18px;

      background-color: transparent !important;

      &.DateInput_input__focused {
        border-bottom: none;
      }
    }

    .DateInput_input-label {
      font-size: 14px !important;
      line-height: 1.2 !important;
    }

    .DateInput {
      background-color: transparent !important;

      &.DateInput__small {
        width: 100%;
      }
    }
  }
}

.SingleDatePicker_picker-calendar {

}

.SingleDatePicker_picker-toolbar {
  position: relative;
  padding: 25px 0 0;
  background-color: #fff;
  width: 100%;

  &:before{
    content: "";
    position: absolute;
    width: 256px;
    height: 1px;
    top: 8px;
    left: -16px;
    background: #D6D6D6;
  }

  .SingleDatePicker_inputs{
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 16px;

    .SingleDatePicker_time-picker {
      max-width: 96px;
      display: inline-block;
      font: 13px sans-serif;
      position: relative;

      > input {
        font: inherit;
        box-sizing: border-box;
        outline: 0;
        background: #fff;

        height: 30px;
        width: 100%;
        padding: 4px 4px 4px 7px;
        font-size: 14px;
        //margin-bottom: 10px;
        border: 1px solid #E0E0E0;
        border-radius: 3px;

        white-space: nowrap;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        color: #424242;

        &:focus {
          outline: none;
        }
      }

      > input:disabled {
        color: #b9b9b9;
      }

      > ul {
        position: absolute;
        top: 100%;
        z-index: 100;
        left: 0;
        right: 0;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        border: 1px solid #e6ebec;
        margin-top: -1px;
        border-radius: 0 0 3px 3px;
        display: none;

        &.open {
          display: block;
        }
      }

      > ul > li > button {
        color: #444;
        padding: 5px 12px;
        cursor: pointer;
        outline: none;
        display: block;
        border: 0;
        width: 100%;
        text-align: left;
        border-radius: 0;
        font: inherit;
        background-color: #fff;

        &.focus {
          background-color: #E0E0E0;
        }
        &:hover {
          background-color: #E0E0E0;
        }
      }
    }

    .SingleDatePicker_timezone-picker {
      max-width: 96px;
      display: inline-block;
      font: 13px sans-serif;
      position: relative;

      > input {
        font: inherit;
        box-sizing: border-box;
        outline: 0;
        background: #fff;

        height: 30px;
        width: 100%;
        padding: 4px 4px 4px 7px;
        font-size: 14px;
        //margin-bottom: 10px;
        border: 1px solid #E0E0E0;
        border-radius: 3px;

        white-space: nowrap;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        color: #424242;

        &:focus {
          outline: none;
        }
      }

      > input:disabled {
        color: #b9b9b9;
      }

      > ul {
        position: absolute;
        top: 100%;
        z-index: 100;
        left: 0;
        right: 0;
        max-height: 200px;
        min-width: 320px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        border: 1px solid #e6ebec;
        margin-top: -1px;
        border-radius: 0 0 3px 3px;
        display: none;

        &.open {
          display: block;
        }
      }

      > ul > li > button {
        color: #444;
        padding: 5px 12px;
        cursor: pointer;
        outline: none;
        display: block;
        border: 0;
        width: 100%;
        text-align: left;
        border-radius: 0;
        font: inherit;
        background-color: #fff;
        white-space: nowrap !important;
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;

        &.focus {
          background-color: #E0E0E0;
        }
        &:hover {
          background-color: #E0E0E0;
        }
      }
    }
  }

  .SingleDatePicker_picker-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-top: 13px;

    &:before{
      content: "";
      position: absolute;
      width: 256px;
      height: 1px;
      top: 0;
      left: -16px;
      background: #D6D6D6;
    }
  }
}


.SingleDatePicker__button{
  width: 60px !important;
  height: 36px !important;
}
.SingleDatePicker__button-apply {
  margin-left: 8px !important
}

.SingleDatePicker_picker {
  //top: 5px !important;
  left: 0;
  z-index: 10;
  background-color: #fff;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

  .DayPicker {
    display: inline-block;
    width: 242px !important;
    border-radius: 3px;
    box-shadow: none !important;

  }
}

.SingleDatePicker_popper-root {
  z-index: 10000 !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.MuiTooltip-popper.SingleDatePicker_timezone-tooltip{
  z-index: 10000;
}
