@use "../../../../css/style-variables" as *;

.more-button {
  height: 24px;
  min-height: 24px !important;
  width: 311px;
  min-width: 311px !important;
  color: $design-blue;
  background: $design-white;
  border: 1px solid $design-gray-60 !important;

  box-shadow: none !important;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-left: 1px;
  align-self: center;

  &:hover {
    color: $design-blue-hover;
    border: 1px solid $design-blue-hover !important;
    text-decoration: none;
    background: $design-white;
  }
  &:active {
    color: $design-blue-hover;
    border: 1px solid $design-blue-hover !important;
    text-decoration: none;
    background: $design-white;
  }
}
