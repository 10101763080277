$columns-button-size: 23px;
$refresh-button-size: 23px;

// design colors

$design-white: #FFFFFF;
$design-gray-10: #FAFAFA;
$design-gray-40: #F5F5F5;
$design-gray-40-dashboard: #F4F4F4;
$design-gray-50: #E8E8E8;
$design-gray-60: #D6D6D6;
$design-gray-70: #BDBDBD;
$design-gray-80: #757575;
$design-gray-90: #323232;
$design-black: #212121;

$design-red-hover: #D22419;
$design-red: #EA3425;
$design-red-10: #FFACAC;
$design-orange: #FF8A00;
$design-yellow: #FFB800;
$design-green: #0AB14D;
$design-green-hover: #01A041;
$design-blue-hover: #1976D2;
$design-blue: #1E88E5;
$design-light-blue: #E3F2FD;

$design-dashboard-gray-40: #F4F4F4;
$design-dashboard-red-10: #FFF6F5;
$design-dashboard-red-20: #FAEAE8;
$design-dashboard-orange: #FFF6E8;
$design-dashboard-mint: #F2FBEB;
$design-dashboard-blue: #F2F8FD;

$design-geyser: #E3ECF0;
$design-apricot-peach: #FFE4DC;
$design-mary-rose: #F8C0BB;
$design-drover: #FDDD32;
$design-drover-10: #FFF1C6;
$design-medyatina-10: #FFFF8A;
$design-medyatina-10-hover: #FFFFA6;
$design-medyatina-20: #F5E979;
$design-mint: #C8F6B2;
$design-tusk: #E5F5D1;
$design-morning-glory: #DCF4F2;
$design-sky: #C9EBFA;
$design-ebb: #DFF3B3;
$design-can-can: #F1DCE6;
$design-pink: #FFDCE8;
$design-pig-pink: #FFEEF0;
$design-pale-prim: #FFFACF;
$design-caper: #F6FACE;
$design-surf-crest: #E7F9E8;
$design-porcelain: #EEF9F9;
$design-ice-cold: #E7F6F8;
$design-light-orchid: #F0D9F4;
$design-lilac: #ECE2FF;
$design-lilac-purple: #E4E7FD;

$design-hover-white: #EDEDED;
$design-hover-gray-10: #D7D7D7;
$design-hover-mint: #D5F8C4;
$design-hover-ebb: #E7F6C5;
$design-hover-red-10: #EF6559;
$design-hover-drover: #FDE563;
$design-hover-gray-80: #969696;
$design-hover-sky: #D6F0FB;
