.emoji-list{
  margin-top: 2px;
  z-index: 5;
}
.emoji-open{
  border-radius: 4px;
  cursor: pointer;
  &.table-cell-emoji{
    margin: -2px 0;
  }

  &:hover{
    fill: rgba(245, 245, 245, 1);
  }

  &:active, &.active-btn{
    fill: rgba(227, 242, 253, 1);
  }

}