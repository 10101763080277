$textFieldColor: #2196F3;

.alc-text-field_underline:after {
  border-bottom: 2px solid $textFieldColor !important;
}

.alc-text-field_underline:hover:not(:disabled):not(:focus):not(.alc-text-field_error):before {
  border-bottom: 2px solid $textFieldColor !important;
}

.alc-text-field_error:after {
  border-bottom-color: #f44336!important;
}

.alc-text-field_input {
  font-size: 14px !important;
  text-overflow: ellipsis !important;
  padding-right: 12px !important;

  &::placeholder {
    color: #bebebe !important;
    opacity: 1 !important;
  }
}

.end-adornment-padding {
  padding-right: 35px !important;
}

.alc-text-field_input_label {
  color: $textFieldColor !important;
}

.alc-text-field_helperText-root {
  height: 28px !important;
}
