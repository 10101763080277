.customer_info {
  background: rgb(232, 245, 233);
  //padding-bottom: 5px;
}

.block-info {
  margin: 8px 0;

  .buttons-container{
    display: flex;
  }
}

.tqi-block {
  position: relative;
}

.customer-debts-row {
  color: red;
}

.communication_row {
  //margin-top: 10px;
  //padding-bottom: 5px;

  &__value{
    font-weight: 500;
  }
  &__comment{
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  &__title {
    overflow: visible;
    max-width: 100%;
  }
}

.comments-icon {
  font-size: 20px;
  //background: url(../../../../../css/img/svg/add_comment.svg) center center / 20px no-repeat;
}

.account-icon {
  font-size: 20px;
  background-size: 21px;
  opacity: 0.5;
}