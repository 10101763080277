@use "../../../css/style-variables" as *;

.new-date-range{
  width: 256px !important;
  margin: 0;
  padding: 12px 16px;
  background: $design-white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.200994);
  border-radius: 3px;

  &__caption{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    height: 33px;
    padding-bottom: 13px;
    margin-bottom: 8px;
    position: relative;

    &__month{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      color: $design-black;
    }

    &:after{
      content: "";
      position: absolute;
      width: 256px;
      height: 1px;
      bottom: 0;
      left: -16px;
      background: $design-gray-60;
    }
  }

  &__head{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    width: 32px;
    height: 32px;
    color: $design-black;
  }
  &__cell{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    color: $design-gray-90;
  }
  &__selected-cell{
    .day-content-wrapper{
      width: 28px;
      height: 28px;
      background-color: $design-blue;
      color: $design-white;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
  &__day{
    width: 32px;
    height: 32px;
    position: relative;
    background-color: transparent;
    .day-content-wrapper{
      width: 28px;
      height: 28px;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &:hover,&:focus{
      .day-content-wrapper{
        border: 1px solid $design-blue-hover;
      }
    }
    &:focus-visible{
      border: 2px solid transparent !important;
    }
    &__today{
      color: $design-blue !important;
      font-weight: 500;
    }
    &__range-end{
      background: $design-light-blue !important;
      color: $design-white;
      border-radius: 0 50% 50% 0;
      .day-content-wrapper{
        background-color: $design-blue;
        color: $design-white;
        font-weight: 400;
      }
      &.disabled{
        opacity: 1 !important;
        background-color: $design-gray-40 !important;
        .day-content-wrapper{
          background-color: $design-gray-50 !important;
          color: $design-gray-70;
        }
      }
    }
    &__range-start{
      background: $design-light-blue !important;
      color: $design-white;
      border-radius: 50% 0 0 50%;
      .day-content-wrapper{
        background-color: $design-blue;
        color: $design-white;
        font-weight: 400;
      }
      &.disabled{
        opacity: 1 !important;
        background-color: $design-gray-40 !important;
        .day-content-wrapper{
          background-color: $design-gray-50 !important;
          color: $design-gray-70;
        }
      }
    }

    &__range-middle{
      background: $design-light-blue !important;
      color: $design-gray-90;
      border-radius: 0;
      .day-content-wrapper{
        background-color: unset;
        color: $design-gray-90;
      }
      &.disabled{
        opacity: 1 !important;
        background-color: $design-gray-40 !important;
        .day-content-wrapper{
          color: $design-gray-70;
        }
      }
    }
    &__day-hovered{
      background-color: $design-light-blue !important;
      &:hover{
        border-radius: 0 50% 50% 0;
      }
    }
    &__day-hovered-reverse{
      background-color: $design-light-blue !important;
      &:hover{
        border-radius: 50% 0 0 50%;
      }
    }
  }

  &__footer{
    position: relative;
    padding: 17px 0 0;
    background-color: $design-white;
    width: 100%;
    &:before{
      content: "";
      position: absolute;
      width: 256px;
      height: 1px;
      top: 8px;
      left: -16px;
      background: $design-gray-60;
    }
  }
  &__ranges{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;
    &__add-btn{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      width: auto;
      min-height: 16px;
      padding: 0;
      color: $design-blue-hover;
      background: none;
      box-shadow: none;
      transition: none;
      align-self: start;
      &:hover{
        background: none;
      }
      &-icon{
        width: 12px;
        height: 12px;
        margin-right: 8px;
        font-size: 0;
        background-image: url("../../../css/img/svg/plus.svg");
      }
    }
  }

  &__buttons{
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-top: 12px;
    &:before{
      content: "";
      position: absolute;
      width: 256px;
      height: 1px;
      top: 0;
      left: -16px;
      background: $design-gray-60;
    }
  }

  &__button {
    margin: 13px 0 0!important;
    width: 60px !important;
    height: 36px !important;
  }
  &__button-apply {
    margin-left: 8px !important;
  }
}

.new-date-range__day__range-start.new-date-range__day__range-end{
  border-radius: 50%;
}
.new-date-range__day__range-start.new-date-range__day__range-end.new-date-range__day__day-hovered{
  border-radius: 50% 0 0 50%;
}
.new-date-range__day__range-start.new-date-range__day__range-end.new-date-range__day__day-hovered-reverse{
  border-radius: 0 50% 50% 0;
}

