.page-form-input{
  padding: 12px !important;
  font-size: 16px !important;
  line-height: 1.25 !important;
  &[aria-hidden="true"]{
    padding: 0 !important;
  }
  &.MuiInputBase-input{
    height: auto;
  }
}

.MuiInputLabel-root.page-form-label{
  font-size: 16px !important;
  line-height: 1.25 !important;
  background: #fff;
  transform: translate(12px, 12px) scale(1);
}

.page-form-root-input{
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}