.button-hidden {
  visibility: hidden !important;
  min-width: 0px !important;
  width: 0px !important;
  min-height: 16px !important;
  height: 16px !important;
}

.menu-open {
  text-decoration: none;
}