.list-filter {
  &__title {
    height: 37px;
    display: flex;
    align-items: center;

    font-family: Roboto,sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 21px;
    letter-spacing: normal;
    text-align: left;
    color: #949494;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: -15px;
    margin-left: -15px;
    height: 37px;
    cursor: pointer;

    &.selected {
      background-color: rgba(33,150,243,.19) !important;
    }

    &:hover {
      background-color: #e3e3e3;
    }
  }
  &__label {
    padding-left: 15px;

    font-family: Roboto,sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 21px;
    letter-spacing: normal;
    text-align: left;
    color: #424242;

    &.selected {
      color: #2196f3;
    }
  }
}
