@use "common/table-variables.scss" as *;

.alc-alphas-table {
  .alc-table-container {
    .alc-table {
      .alc-table-body {
        .alc-table-row {

          &:hover {
            .alc-table-td {
              &.hovered-by-row {
                .alc-table-flex-container {
                  .alc-table-hovered-button {
                    visibility: visible !important;

                    &.align-right {
                      margin-left: auto !important;
                    }

                    &.align-center {
                      margin-left: auto !important;
                      margin-right: auto !important;
                    }
                  }
                }
              }
            }
          }

          &.alc-table-row-cell-menu-open {
            .hovered-by-row {
              .alc-table-hovered-button {
                //display: inline-flex !important;
                //visibility: visible !important;

                //todo проверить возможно стили не актуальные уже
                &.align-right {
                  margin-left: auto !important;
                }

                &.align-center {
                  margin-left: auto !important;
                  margin-right: auto !important;
                }
              }
            }
          }

          .alc-table-td {
            .alc-table-td-button {
              align-self: center;
              background-color: transparent;
              box-shadow: none !important;
              //margin-right: -5px !important;
              font-size: 18px;
              border: 0;
              vertical-align: bottom;
              padding: 0 !important;

              margin-left: auto !important;

              .alc-table-td-icon {
                color: $td-color;
              }

              &.align-right {
                //float: right;
                margin-left: auto !important;
              }

              &.align-center {
                margin-left: auto !important;
                margin-right: auto !important;
              }
            }
            .alc-table-td-svg-button {
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
            }
            .alc-table-td-custom-button {
              display: flex;
              &.align-right {
                margin-left: auto !important;
              }

              &.align-center {
                margin-left: auto !important;
                margin-right: auto !important;
              }
            }
            .alc-table-td-button:hover {
              text-decoration: none;
            }
            .alc-table-td-button:active {
              text-decoration: none;
            }

            &.cell-selected:not(.cell-disabled) {
              .alc-table-hovered-button {
                visibility: visible;
              }
            }

            .alc-table-hovered-button {
              visibility: hidden;
              &.visible{
                visibility: visible;
              }
            }

            //todo показывать кнопку при наведении
            &.hovered-by-cell:hover:not(.cell-disabled) {
              .alc-table-hovered-button {
                visibility: visible !important;

                &.align-right {
                  margin-left: auto !important;
                }

                &.align-center {
                  margin-left: auto !important;
                  margin-right: auto !important;
                }
              }
            }
          }

          &.alc-table-row-hovered:hover {
            .hovered-by-row {
              .alc-table-hovered-button {
                visibility: visible !important;

                &.align-right {
                  margin-left: auto !important;
                }

                &.align-center {
                  margin-left: auto !important;
                  margin-right: auto !important;
                }
              }
            }
          }
        }
      }
    }
  }
}