.block {
  z-index: 1310;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;

  .block_overlay {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0 !important;
  }
}