@use "../../../components/Table/css/common/table-variables.scss" as table;
@use "../../../css/style-variables" as *;

.panel_info {
  padding: 12px 16px;
  font-family: table.$font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $design-gray-90;
  background: $design-gray-40;

  &__block{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__indent {
    margin-bottom: 8px;
  }

  &__buttons_indent {
    padding-bottom: 6px !important;
  }

  &__title {
    margin-bottom: 6px;
    font-weight: 500;
  }

  .medium-font {
    font-size: 14px;
  }

  .small-font {
    font-size: 12px;
  }

  &__warning {
    color: $design-red !important;
  }

  &__grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;

    &_title {
      overflow: hidden;
      max-width: 80px;
      max-height: 17px;
      word-wrap: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &_value {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__grid-row-full{
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  &__row {
    font-size: 0.75rem;
    line-height: 1rem;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;

    //margin: 2px 0;
    position: relative;

    &_title {
      display: flex;
      align-items: center;

      overflow: hidden;
      max-width: 80px;
      max-height: 17px;
      word-wrap: normal;
      text-overflow: ellipsis;
    }

    &_title-value {
      overflow: hidden;
      max-width: 80px;
      max-height: 17px;
      word-wrap: normal;
      text-overflow: ellipsis;
    }

    &_value {
      position: absolute;
      left: 85px;
      top: 0;
      bottom: 0;
      right: 0;

      overflow: hidden;
      max-width: 85px;
      max-height: 17px;
      word-wrap: normal;
      text-overflow: ellipsis;
    }

    .margin-bottom {
      margin-bottom: 8px;
    }

    .icon-height {
      height: 16px;
      width: 20px;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
  }
}

.custom-value{
  display: flex;
  align-items: center;
  height: 16px;

  .icon-size {
    font-size: 13px;
    background-size: 15px;
    margin-right: 8px;
  }
}

.more_weight {
  font-weight: 500;
}

.panel_divider {
  margin-bottom: 10px;
}

.radius-small {
  border-radius: 3px;
}

.autocomplete-panel {
  border-radius: 3px;
  margin: 0 !important;
}

hr {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  //margin: 5px 0 !important;
}

.icon-margin-right {
  margin-right: 5px;
}

.icon-margin-bottom {
  margin-bottom: 2px;
}

.panel_info_negative {
  background: rgba(255, 246, 245, 1) !important;
}

.panel_info_neutral {
  background: $design-light-blue;
}
