@use "../../../css/common/table-variables.scss" as *;
.date-input-textField_input {
  font-size: 14px !important;
}

.date-input-container {
  &:focus-within {
    .date-input-label {
      color: #0c7cd5;
    }
  }
}

.date-margin-dense-input-container {
  margin: 0px !important;
}

.date-input-label {
  height: 32px;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #888888;
  padding-left: 12px;
}