@use "../common/table-variables.scss" as *;

.alc-alphas-table {
  .alc-table-container {
    .alc-table {
      .alc-table-body {
        .alc-table-row {
          //выделяем колонку с id

          &.alc-table-row-highlight {
            &.alc-table-row-selected, &.alc-table-row-multiple-selected {
              .alc-table-cell:not(.alc-cell-empty):not(.cell-selected):not(.cell-multiple-selected) {
                background-color: $row-focus-color;
              }
            }
          }

          &.alc-table-row-checked {
            .alc-table-cell:not(.alc-cell-empty):not(.focus-cell):not(.focusing-cell) {
              background-color: $cell-focusing-color !important;
              &.default-empty {
                color: $td-color;
              }
            }
          }

          &.alc-table-row-hovered:hover {

            .alc-table-cell:not(.alc-cell-empty):not(.cell-selected):not(.cell-multiple-selected) {
              background-color: $row-focus-color;
            }

            &.alc-table-row-checked {
              .alc-table-cell:not(.alc-cell-empty):not(.focus-cell):not(.focusing-cell) {
                background-color: #f6fbfe !important;
              }
            }


            &:not([style]) {
              .alc-table-cell:not(.alc-cell-empty):not(.cell-selected):not(.cell-multiple-selected) {
                //background-color: $row-focus-color !important;
              }

              .alc-cell-empty:not(.cell-selected):not(.cell-multiple-selected) {
                //opacity: 0.8 !important;
              }
            }

            &[style] {
              .alc-table-cell:not(.alc-cell-empty):not(.cell-selected):not(.cell-multiple-selected) {
                //background-color: rgba(33, 33, 33, 0.1) !important;
              }

            }
          }
        }
      }
    }
  }
}