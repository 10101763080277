$vertical-track-width: 11px;
$horizontal-track-width: 11px;
$background-color: #F5F5F5;

.scrollbars {
  //background-color: $background-color;

  .track-vertical {
    padding: 2px;
    z-index: 10;
    position: absolute;
    width: $vertical-track-width !important;
    right: 0;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background-color: transparent;

    .thumb-vertical {
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      background-color: #adafb3;
    }
  }

  .track-horizontal {
    padding: 2px;
    z-index: 10;
    position: absolute;
    height: $horizontal-track-width !important;
    right: 2px;
    bottom: 0;
    left: 2px;
    border-radius: 3px;
    margin-right: 7px;
    background-color: transparent;

    .thumb-horizontal {
      position: relative;
      display: block;
      height: 100%;
      cursor: pointer;
      border-radius: 8px;
      background-color: #adafb3;

    }
  }
}

.disable-scroll {
  overflow: hidden !important;
  height: 100% !important;
  margin-right: 0px !important;
}