@use "../../../css/style-variables" as *;

.icon-button {
  flex: 0 0 auto;
  overflow: visible;

  border: 0;
  margin: 0;
  cursor: pointer;
  display: none;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.icon-button-display {
  display: inline-flex;
}

.icon-svg {
  color: $design-gray-90;
  &:hover,&:focus {
    color: $design-blue-hover;
  }
  &:active {
    color: $design-gray-90;
  }
}

.icon-button-disabled {
  pointer-events: none;
  cursor: not-allowed;
  text-decoration: none;
  .icon-svg {
    color: $design-gray-60;
  }
}