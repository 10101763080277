@use "../../../components/Table/css/common/table-variables.scss" as table;
@use "../../../css/style-variables" as *;

.filled-input {
  &__container {
    &:focus-within {
      .filled-input__over-label {
        color: $design-blue;
      }
    }
  }
  &__over-label {
    height: 32px;
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #888888;
    padding-left: 12px;
  }
  &__margin-dense-input-container {
    margin: 0 !important;
  }
  &__over-label-input {
    padding: 9px 12px 10px 12px !important;
  }
  &__label {
    font-size: 14px !important;
    line-height: 1.2 !important;
    color: $design-gray-70;
  }
  &__focused-label {
    color: $design-blue !important;
  }

  &__input {
  font-family: table.$font-family !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: $design-gray-90 !important;
  }

  &__sidebar-input{
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 9px 12px !important;
    &[aria-hidden="true"]{
      padding: 0 !important;
    }
  }

  &__sidebar-input-element{
    border: 1px solid transparent !important;
    &.MuiInputBase-input{
      height: auto;
    }
    &.input-paddings{
      padding: 9px 12px;
    }
  }

  &__header-input {
    font-family: table.$font-family !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #424242 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__multiline {
    display: flex !important;
    align-items: flex-end !important;
    padding: 0 !important;
  }

  &__end-adornment {
    position: absolute;
    display: flex !important;
    align-items: baseline !important;
    height: auto !important;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__close-icon {
    display: flex;
    padding-right: 4px;
    svg {
      cursor: pointer;
    }
  }

  &__input-tag {
    padding-right: 35px !important;
  }
}


.green {
  color: $design-green !important;
}

.red {
  color: $design-red !important;
}

.hidden-end-adornment {
  padding-right: 0 !important;
}

.end-adornment {
  padding-right: 40px !important;
}

.MuiOutlinedInput-notchedOutline.input-notchedOutline{
  border-color: $design-gray-70;
}

.MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline.input-notchedOutline{
  border-color: $design-gray-70;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline.input-notchedOutline{
  border-color: $design-blue;
  border-width: 1px;
}

.MuiFormLabel-root.filled-input__label{
  color: $design-gray-70;
  transform: translate(12px, 10px) scale(1);
}

.MuiInputLabel-outlined.MuiInputLabel-shrink.filled-input__label{
  transform: translate(12px, -6px) scale(1);
  font-size: 10px !important;
}