@use "../../../../css/style-variables" as *;

$row-height: 32px;
$row-line-height: 32px;
$fixed-row-selection-left: 0;
$fixed-custom-left: 29px;

$th-color: $design-black;
$td-color: $design-black;
$background-color: $design-dashboard-gray-40;
$border-color: #E0E0E0;
$row-focus-color: $design-hover-white;
$cell-focus-color: $design-blue;
$cell-focusing-color: #F0F8FD;
$base-padding: 8px;
$fixed-cell-padding: 6px;
$cell-padding: 7px;
$empty-cell-red: #F44336;
$font-family: Roboto, sans-serif;

@mixin selection-cell{
  outline: $cell-focus-color solid 2px;
  outline-offset: -2px;
}
