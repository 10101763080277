@use "../../../css/common/table-variables.scss"  as *;

.alc-input-container {
  height: 100%;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;

  .alc-textarea-input {
    align-self: flex-start;
    height: 100%;
    padding-top: 9px !important;
    padding-bottom: 7px !important;
    padding-left: 7px;
    padding-right: 7px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;

    > div {
      height: 100%;
      > textarea {
        //height: 100%;
      }
    }
  }
}
