@use "../../../components/Table/css/common/table-variables.scss" as table;
@use "../../../css/style-variables" as *;

.page-form {
  &__step-title {
    margin: 20px 0 4px;
    font-family: table.$font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #212121;
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 48px 0 160px;

    margin-left: auto;
    margin-right: auto;
    max-width: 492px;

    .upload-block{
      margin-bottom: 24px;
    }
    @media("max-width:768px") {
      padding: 48px 16px 160px;
    }
  }
  &__item {
    &.checkbox-item, &.recapture-item {
      padding-bottom: 24px;
      .label-error {
        color: $design-red;
      }
    }
  }
  &__title {
    font-family: table.$font-family;
    font-size: 14px;
    display: block;
    margin-bottom: 12px;
  }
  &__submit-button {
    display: flex;
    align-items: center;
  }
  &__notify-container {
    margin-top: 64px;
  }
  &__description {
    margin: 0 0 32px;
    font-size: 14px;
    line-height: 20px;
  }
}

.staff-submit-button {
  width: 100%;
  height: 44px;
}

.staff-submit-loading {

}

.staff-back-button {
  height: 44px;
  margin-right: 16px;
}