.debts_top_10 {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    .info-row .right {
      text-align: right;
      font-weight: 400;
    }
  }

  &__row {
    &.margin {
      margin-top: 10px;
    }
  }

  &__rows, &__row {
    .info-row .right {
      text-align: right;
    }
    .info-row .left {
      white-space: nowrap;
    }
  }
}

