@use "../../../components/Table/css/common/table-variables.scss" as *;

.form-textarea {

  &__container &__pen-icon-container{
    visibility: hidden;
  }

  &__container:hover &__pen-icon-container{
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 4px;
    cursor: pointer;
  }

  &__container.sidebar-element{
    position: relative;
  }

  &__pen-icon-container:hover {
    background-color: #ebebeb;
  }

  &__pen-icon {
    font-size: 12px !important;
    color: #888888;
  }

  &__label-container {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 2px;
    z-index: 2;
  }

  &__label {
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #888888;
    padding-left: 12px;
  }
}

.label-focus {
  color: #2196f3;
}
