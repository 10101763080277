@use "./style-variables" as *;

.translator-dashboard__alpha-button {
  margin-right: 16px;

  min-height: 20px;
  max-height: 20px;
  min-width: 56px;
  max-width: 56px;

  color: $design-white;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  border-radius: 3px;
}
.translator-dashboard__reject{
  background-color: $design-white;
  color: $design-blue;
  border: 1px solid $design-blue;
  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.12);
  &:hover {
    background-color: $design-white !important;
  }
}
.translator-dashboard__give-up {
  background-color: $design-red;
  box-shadow: 0 5px 7px -4px rgba(234, 52, 37, 0.50);
  &:hover {
    background-color: $design-red-hover !important;
    box-shadow: 0 5px 7px -4px rgba(210, 36, 25, 0.50);
  }
}
.translator-dashboard__accept {
  background-color: $design-blue;
  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.50);
  &:hover {
    background-color: $design-blue-hover !important;
  }
}

//todo перенести в shared
.alpha-hash-link {
  cursor: pointer;
  color: $design-blue !important;
  text-decoration: none;
  display: inline;
  margin: 0;
  opacity: 1;
  white-space: nowrap;

  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1875em;
  letter-spacing: normal;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
}

.translator-dashboard-row {

  &.translator-dashboard__row-green {
    .alc-table-cell {
      background-color: #e4f3e5 !important;
    }
  }

  &.translator-dashboard__row-blue {
    .alc-table-cell {
      background-color: #bcdffb !important;
    }
  }

  &.alc-table-row-selected,
  &.alc-table-row-multiple-selected,
  &.alc-table-row-hovered:hover {

    &.translator-dashboard__row-green {
      .alc-table-cell {
        background-color: rgba(228, 243, 229, 0.6) !important;
      }
    }

    &.translator-dashboard__row-blue {
      .alc-table-cell {
        background-color: rgba(188, 223, 251, 0.6) !important;
      }
    }

    .alc-table-cell {
    }

  }
}
