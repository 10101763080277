@use "../../../../css/style-variables" as *;
@use "../common/table-variables.scss" as table;

.alc-alphas-table {

  .alc-table-container {
    .alc-table {
      .alc-table-body {
        .alc-table-row {
          .alc-table-td {
            &.cell-selected:not(.cell-link-checkbox) {
              @include table.selection-cell;

              .number-type {
                justify-content: flex-end;
                padding-right: table.$cell-padding !important;
              }
            }

            &.cell-selected.cell-link-checkbox {
              .textarea__container {
                @include table.selection-cell;
              }
            }
          }
        }
      }
    }
  }
}