@use "src/css/style-variables" as *;

.input-arrow-button {
  &.empty {
    .icon-svg {
      color: $design-gray-70;
    }
  }
  &.open {
    transform: rotate(180deg);
  }
}