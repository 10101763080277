.icon_close-io {
  background: url(img/svg/logo/close-io-icon.svg) no-repeat center center;
}

.icon_complain {
  background: url(img/svg/skull-icon.svg) no-repeat center center;
}

.icon_account {
  background: url(img/svg/account.svg) no-repeat center center;
}

.icon_send {
  background: url(img/svg/send-icon.svg) no-repeat center center;
}
.icon-little-exit {
  background: url(img/svg/litle-exit-icon.svg) no-repeat center center;
}
.icon_trello {
  background: url(img/svg/logo/trello-icon.svg) no-repeat center center;
}

.icon_new-translator {
  background: url(img/svg/emoji/new-translator-emoji.svg) no-repeat center center;
}

.icon_refresh {
  color: #323232;
  &:hover{
    color: #1976D2;
  }
}

.icon_sorting-arrow {
  background: url(img/svg/sorting-arrow.svg) no-repeat center center;
}

.icon_panda {
  background: url(img/svg/alpha-panda-icon.svg) no-repeat center center;
}

.icon_description {
  background: url(img/svg/description.svg) no-repeat center center;
}

.icon_customer_risk {
  background: url(img/svg/customer_risks.svg) no-repeat center center;
}

.icon_deadline_risk {
  background: url(img/svg/deadline_risks.svg) no-repeat center center;
}

.icon_quality_risk {
  background: url(img/svg/quality_risks.svg) no-repeat center center;
}

.icon_show_password {
  background: url(img/svg/show_password.svg) no-repeat center center;
}

.icon_hide_password {
  background: url(img/svg/hide_password.svg) no-repeat center center;
}

.icon_project {
  background: url(img/svg/project-task.svg) no-repeat center center;
  width: 16px;
  height: 16px;
}

.icon_add-user {
  background: url(img/svg/add_user.svg) no-repeat center center;
}
.icon_clock {
  background: url(img/svg/clock.svg) no-repeat center center;
}

.icon_attach-files {
  background: url(img/svg/attach-files_ico.svg) no-repeat center center;
}

.icon_input-delete {
  background: url(img/svg/input-delete_ico.svg) no-repeat center center;
}

.icon_small-size {
  font-size: 14px;
}

.icon_medium-size {
  font-size: 20px;
}

.icon_large-size {
  font-size: 23px;
}

.icon_green {
  color: #43a047 !important;
}

.icon_blue {
  color: #007aff !important;
}

.icon_empty-cell {
  font-size: 14px;
  color: white !important;
}

.icon-filter-red {
  filter: invert(51%) sepia(25%) saturate(6610%) hue-rotate(331deg) brightness(88%) contrast(121%) !important;
}

.icon-filter-blue {
  filter: invert(35%) sepia(84%) saturate(3329%) hue-rotate(199deg) brightness(99%) contrast(111%);
}

.icon-filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(251deg) brightness(102%) contrast(101%);
}

.icon-opacity-30 {
  opacity: 0.3;
}
