@use "../../../css/style-variables" as *;

.MuiCheckbox-root{
  &.checkbox-mui{
    .checkbox-icon{
      stroke: none;
      color: $design-gray-70;
      rect{
        fill: transparent;
      }

      &.checked{
        color: $design-blue;
        stroke: none;
        rect{
          fill: $design-blue;
        }
      }
    }
    &:hover{
      background-color: transparent;
    }

    input{
      &:hover:not(&:disabled) ~ .checkbox-icon {
        stroke: none;
        color: $design-blue-hover;
        &.checked{
          stroke: none;
          rect{
            fill: $design-blue-hover;
          }
        }
      }
      &:disabled ~ .checkbox-icon {
        stroke: none;
        color: $design-gray-70;
        rect{
          fill: $design-gray-40;
        }
        &.checked{
          rect{
            fill: $design-gray-70;
          }
        }
      }
    }
  }
}
.MuiCheckbox-root{
  padding: 0 !important;
}
