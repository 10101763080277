@use "../../../../../css/style-variables" as *;

.select-with-search {
  &__value {
    align-self: flex-start;
    padding: 9px 7px 6px;
    width: 100%;
    align-items: center;
    overflow: hidden;
    white-space: pre;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    outline: none;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 32px;
  }
  &__select-container {
    min-width: 320px !important;
    padding: 0 !important;
    overflow-y: hidden;
    outline: none;
    z-index: 6 !important;
  }

  &__option-container {
    color: $design-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    height: 230px;
  }
  &__option-item {
    height: 32px;
    display: flex;
    align-items: center;

    .id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 88px;
      margin-left: 12px;
      font-weight: 500;
    }

    .single-id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin-left: 12px;
    }

    .value {
      margin-left: 24px;
      padding-right: 12px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 160px;
    }

    &.selected, &:hover {
      background-color: $design-gray-40;
    }
  }

  &__checkbox-container {
    margin-left: 16px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    border-top: 1px solid $design-gray-60;
    margin-top: 8px;
  }
  &__modal-button {
    margin: 0 !important;
    width: auto;
    height: 36px;
    padding: 10px 12px;
    text-transform: capitalize;

    &.accept{
      background-color: $design-blue;
      color: $design-white;
      &:hover, &:focus{
        background-color: $design-blue-hover
      }
    }

    &.cancel{
      margin-right: 8px !important;
      background-color: $design-white;
      color: $design-gray-90;
      box-shadow: none;
      &:hover, &:focus{
        background-color: $design-gray-50;
      }
    }
  }
  &__no-result-label{
    text-align: center;
    height: 32px;
    color: grey;
    padding: 9px 0;
  }
}
