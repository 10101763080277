@use "../../../../../components/Table/css/common/table-variables.scss" as *;

.edit-form {

  &__form-control {
    width: 100% !important;
  }

  &__label {
    padding-left: 6px !important;
    z-index: 10;
  }

  &__input {
    font-size: 14px !important;
    background-color: #e8e8e8 ;

    border-radius: 4px 4px 0 0;
    padding: 9px 12px 10px 12px !important;

    font-family: $font-family;
    font-weight: 300 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 16px !important;
    letter-spacing: normal !important;

    &:hover {
      background-color: #dedede;
    }
  }

  &__input:focus-within {
    background-color: #dcdcdc;
  }


  &__buttons {
    display: flex;
    margin-top: 8px;
  }

  &__save-button {
    margin-right: 8px !important;
    padding: 8px 12px !important;
    height: 36px;
  }

  &__cancel-button {
    line-height: 16px !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    height: 36px !important;
  }
}
