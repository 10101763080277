.nothing-found {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1875em;
  letter-spacing: normal;
  text-align: left;
  outline: none;
  text-overflow: ellipsis;

  white-space: nowrap;
  user-select: none !important;
}
