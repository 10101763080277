.transform-menu {
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  top: 100%;
  left: 0;

  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.08);

  &:focus {
    outline-color: transparent;
  }
}