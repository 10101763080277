@use "../../../css/style-variables" as *;

.alpha-link {
  cursor: pointer;
  color: $design-blue !important;
  text-decoration: none;
  display: inline;
  margin: 0;
  opacity: 1;
}

.chili-link{
  color: $design-black !important;
}

.alpha-link:hover {
  text-decoration: underline;
}