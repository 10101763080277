@use "./style-variables" as *;
.alpha-dashboard {
  &__container {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: flex-start;
    padding: 48px 0;
  }
  &__table {
    min-width: 494px;
    position: relative;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 16px;
  }
  &__spinner {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  &__rows {
    border: 1px solid $design-gray-60;
    border-radius: 4px;
    overflow: hidden;
  }
  &__row {
    display: flex;
    &:nth-child(odd) {
      background: $design-gray-10;
    }
  }
  &__cell {
    display: flex;

    color: $design-black;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 10px 8px;
    &:first-child{
      padding-left: 16px;
    }
    &.cell{
      &-index{
        width: 40px;
      }
      &-title{
        width: 224px;
      }
      &-value{
        width: 230px;
      }
    }
  }
}

.accepted-status {
  color: $design-green;
}

.reject-button {
  background-color: $design-white !important;
  color: $design-blue;
  border: 1px solid $design-blue;
  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.12);

  &:hover {
    background-color: $design-white !important;
  }
  &:disabled {
    color: $design-gray-70 !important;
    border: 1px solid $design-gray-70 !important;
  }
}

.give-up-button {
  background-color: $design-white !important;
  color: $design-red !important;
  border: 1px solid $design-red;
  box-shadow: 0 5px 7px -4px rgba(234, 52, 37, 0.12);

  &:hover, &:active, &:focus {
    background-color: $design-white !important;
    color: $design-red-hover;
  }
  &:disabled {
    color: $design-gray-70 !important;
    border: 1px solid $design-gray-70 !important;
  }
}

.reject-button, .accept-button, .give-up-button {
  width: calc(50% - 8px);
}

@media only screen and (max-width: 480px) {
  .alpha-dashboard {
    min-width: 100%;
    padding: 0 20px;
  }
}