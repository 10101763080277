.dashboard-banner{
  &__container{
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;

    width: 400px;
    height: 180px;
    border-radius: 8px;
    background: #FFF6E8;
    padding: 30px 24px;
    text-decoration: none;
    position: absolute;
    left: calc(100% + 32px);
    top: 0;
  }
  &__text-block{
    width: 170px;
    color: #212121;
    font-size: 14px;
    line-height: 16px;

    .banner-title{
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
}

@media only screen and (max-width: 1255px) {
  .dashboard-banner {
    &__container{
      position: unset;
      margin: 28px 0;
    }
  }
}