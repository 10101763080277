.hunt-and-hire-detail {
  &__container {
    height: 100%;
    .filter-autocomplete-menu {
      .filter-autocomplete-item-label {
        display: flex;
        .id {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 88px;
          font-weight: 500;
        }

        .value {
          margin-left: 24px;
          padding-right: 12px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 160px;
        }
      }
    }
    .page-form__item.multiple-select {
      .filter-autocomplete-item-label {
        padding: 0;
        .id {
          margin-left: 12px;
        }
      }
    }
  }
  &__form-container{
    height: 100%;
  }
  &__number-input::-webkit-outer-spin-button,
  &__number-input::-webkit-inner-spin-button,{
    -webkit-appearance: none;
    margin: 0;
  }
  &__number-input[type=number]{
    -moz-appearance: textfield;
  }
}