.group-row-custom {
  display: table-row;
  position: relative;
  height: 48px;
  background-color: #FFFFFF !important;

  &__hidden {
    height: 0 !important;
  }

  &__hidden-with-border {
    height: 0 !important;
  }

  &__cell-fixed {
    padding: 0 !important;
    box-shadow: inset -1px 0px #E0E0E0;
  }

  &__cell {
    display: none;
    padding: 0 !important;
  }

  &__container {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible;
    width: 1px;

  }
}
