@use "../../../../../css/style-variables" as *;

.filter-autocomplete-input {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: currentColor;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
  text-overflow: ellipsis;
  padding: 9px 2px 9px 12px;

  &::placeholder {
    color: $design-gray-70;
  }
}

.filter-autocomplete-input-default-label {
  position: absolute;
  font-size: 0.875rem;
  line-height: 1rem;
  cursor: text;
  z-index: 2;
  top: 9px;
  left: 12px;
  background: $design-white;
  color: $design-gray-70;
  transition: all 0.2s ease;
}

.filter-autocomplete-input:focus + .filter-autocomplete-input-default-label,
.filter-autocomplete-input:valid + .filter-autocomplete-input-default-label{
  font-size: 10px !important;
  line-height: 1.172 !important;
  top: -7px;
}
.filter-autocomplete-input:focus + .filter-autocomplete-input-default-label{
  color: $design-blue;
}

.filter-autocomplete-input-container {
  min-height: 36px;
  border: 1px solid $design-gray-70;
  border-radius: 4px;
  //position: relative;
  position: sticky;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.1875em;
  align-items: center;

  &:focus-within{
    border: 1px solid $design-blue;
  }
  &-error {
    border: 1px solid $design-red;
    .filter-autocomplete-input-default-label {
      color: $design-red;
    }
  }
}

.filter-autocomplete-input-container-disabled {
  pointer-events: none;
}

.filter-autocomplete-button {
  position: relative;
  right: 0;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;
  transition: unset;
  &:hover,&:active{
    background: unset !important;
  }
  &.icon-button-ripple:after{
    transition: unset;
  }
}

.filter-autocomplete-button-icon {
  font-size: 16px;
  padding: 0;
  color: rgba(50, 50, 50, 1);
}
.filter-autocomplete-close-button{
  margin-right: 4px;
  line-height: 1;
  display: flex;
  &.tags-wrapper{
    margin-right: 4px;
  }
}
.filter-autocomplete-arrow-button{
  padding-right: 12px;
  &.tags-wrapper{
    position: absolute;
    top: 7px;
    right: 0;
  }
}

.MuiTooltip-arrow.filter-tooltip-arrow{
  color: $design-black;
  top: 2px;
}
.MuiTooltip-tooltip.filter-tooltip{
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $design-black !important;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0 14px;
}
.MuiTooltip-arrow.filter-tooltip-arrow{
  color: $design-black;
  top: 2px;
}
.MuiTooltip-tooltip.filter-tooltip{
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $design-black !important;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0 14px;
}
