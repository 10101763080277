@use "../../../components/Table/css/common/table-variables.scss" as *;

.user-menu {
  display: flex;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &.loader{
      margin-right: 17px;
    }
  }

  &__button {
    padding: 12px 16px !important;
    color: #FFFFFF;
    text-transform: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    &:hover{
      color: #BDBDBD;
    }
  }

  &__list {}
  &__header {
    height: 48px !important;
    padding: 7px 16px !important;
    //height: auto !important;
    //padding: 0 !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-start !important;

    &-title {
      white-space: nowrap;
      font-family: $font-family;
      font-size: 16px !important;
      //font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
    }
    &-email {
      white-space: nowrap;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: left;
      color: #424242;
    }
  }

  &__logout-item {
    padding-left: 16px !important;
    &-icon {
      color: #707070 !important;
      margin-right: 5px !important;
    }

    &-title {
      white-space: nowrap;
      font-family: $font-family;
      font-size: 16px !important;
      //font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
    }
  }
}

.user-menu__popper_arrow {
  position: absolute !important;
  font-size: 7px !important;
  width: 3em !important;
  height: 3em !important;
}

.user-menu__popper_arrow::before {
  content: '""' !important;
  margin: auto !important;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid !important;
}
