.page-loader {
  display: none !important;
  color: #2196F3 !important;
}

.page-loader_display {
  display: inline-block !important;
}
.input__loading {
  margin-right: 6px;
}