@use "../../../components/Table/css/common/table-variables.scss" as table;
@use "../../../css/style-variables" as *;

.button-root {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-height: 28px;
  min-width: 28px;
  padding: 0 18px;
  border: 0;
  margin: 0;
  border-radius: 3px;

  background-color: $design-blue;

  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.5);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: $design-blue-hover;
  }

  &:disabled {
    color: #898989 !important;
    background-color: #cccccc;
  }
  &:active {
    box-shadow: none;
  }
}

.button-root:disabled {
  background-color: #e0e0e0;
  color: #a6a6a6;
  box-shadow: none;
  pointer-events: none;
}

.button-text {
  color: $design-white;
  font-size: 0.875rem;
  //line-height: 1.75;
  font-weight: 500;
  font-family: table.$font-family;
  text-transform: none;
  white-space: nowrap;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, $design-white 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .2s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.button_type-text {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
   color: rgba(0, 0, 0, 0.26) !important;
  }
}

.modal-accept-button {
  margin: 0 !important;
  width: auto;
  height: 32px;
  padding: 6px 18px;
  background-color: $design-blue;
  text-transform: capitalize;

  &:hover {
    background-color: $design-blue-hover;
  }

  &:disabled {
    color: #898989 !important;
    background-color: #cccccc;
  }
  &:focus {
    background-color: $design-blue-hover;
  }
  &:active {
    box-shadow: none;
  }
}

.modal-cancel-button {
  margin: 0 !important;
  margin-right: 8px !important;
  background-color: $design-white;
  color: #424242;
  box-shadow: none;
  width: auto;
  height: 32px;
  padding: 6px 18px;
  text-transform: capitalize;

  &:hover {
    background-color: #efefef;
  }

  &:disabled {
    color: #979797 !important;
    background-color: $design-white;
  }

  &:focus {
    background-color: #efefef;
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
    background-color: #BBDEFB;
  }
}

.buttons{
  display: inline-flex;
  width: auto;
  height: 36px !important;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;

  &-primary{
    color: $design-white;
    background: $design-blue;
    box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.50);
    &:hover, &:focus{
      background: $design-blue-hover;
    }
    &:active{
      background: $design-blue-hover;
      box-shadow: none;
    }
    &:disabled{
      background: $design-gray-70;
    }
  }

  &-secondary{
    box-shadow: none;
    background: $design-white;
    color: $design-gray-90;
    &:hover, &:focus{
      background: $design-gray-40;
    }
    &:active{
      background: $design-light-blue;
    }
    &:disabled{
      background: $design-gray-40;
      color: $design-gray-70;
    }
  }



}

.button-back {
  position: relative;
  background: $design-white;
  color: $design-blue;
  border: 1px solid $design-blue;
  border-radius: 4px;
  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.12);
  font-family: table.$font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &:before {
    content: '←';
    margin-right: 4px;
  }

  &:hover {
    color: $design-blue-hover;
    border: 1px solid $design-blue-hover;
    background: $design-white;
    border-radius: 4px;
    box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.12);
  }
}
