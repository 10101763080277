.not-found {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__oops-mesage {
    font-size: 28px;
    font-weight: 500;
  }
  &__try-again-message {
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }
  &__404-kitty {
    background: url(../../../css/img/content/404-kitty.png) no-repeat center center;;
    margin-top: 64px;
    width: 600px;
    height: 200px;
  }
}