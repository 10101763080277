@use "./style-variables" as *;

.modal-accept-button {
  margin: 0 !important;
  width: auto;
  height: 32px;
  padding: 6px 18px;
  background-color: $design-blue;
  text-transform: capitalize;

  &.left-accept-button{
    margin-right: 8px !important;
  }
  &:hover {
    background-color: $design-blue-hover;
  }

  &:disabled {
    color: $design-gray-70 !important;
    background-color: $design-gray-40;
  }
  &:focus {
    background-color: $design-blue-hover;
  }
  &:active {
    box-shadow: none;
  }
}

.modal-cancel-button {
  margin: 0 !important;
  margin-right: 8px !important;
  background-color: $design-white;
  color: $design-gray-90;
  box-shadow: none;
  width: auto;
  height: 32px;
  padding: 6px 18px;
  text-transform: capitalize;

  &:hover {
    background-color: $design-gray-40;
  }

  &:disabled {
    color: #979797 !important;
    background-color: $design-white;
  }

  &:focus {
    background-color: $design-gray-40;
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
    background-color: $design-light-blue;
  }
}

.ripple-cancel-button::after {
  background-image: radial-gradient(circle, $design-blue 10%, transparent 10.01%);
}

.modal-buttons-container {
  padding: 0 24px 24px !important;
  margin: 0 !important;
}

.margin-save-button {
  margin-right: 8px !important;
}

.very-primary-button{
  display: inline-flex;
  height: 44px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 4px;
  background: $design-blue;
  box-shadow: 0 5px 7px -4px rgba(0, 75, 140, 0.50);

  color: $design-white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  &:hover, &:focus, &:active{
    background: rgba(25, 118, 210, 1);
  }
  &:disabled{
    color: $design-white !important;
    background: $design-gray-70;
  }
}