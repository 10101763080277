@use "../../../css/style-variables" as *;

.table-button-icon {
  color: $design-gray-90;

  &.icon_empty-cell {
    color: $design-white;
  }

  &:hover, &:focus {
    color: $design-blue-hover !important;
  }

  &:active {
    color: $design-gray-90;
  }
}