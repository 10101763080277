@use "../../../components/Table/css/common/table-variables.scss" as *;

.icon-button-root {
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  color: inherit;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: none;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.icon-button-display {
  display: inline-flex;
}

.icon-button-root:hover,
.icon-button-root:focus {
  background-color: rgba(0, 0, 0, 0.08);
}

.icon-button-root:active {
  background-color: rgba(0, 0, 0, 0.17) !important;
}

.icon-button-text {
  font-size: 0.875rem;
  line-height: 1.75;
  font-weight: 500;
  font-family: $font-family;
  text-transform: none;
  white-space: nowrap;
}

.icon-button-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  text-decoration: none;
}

.icon-button-ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.icon-button-ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #6a6a6a 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .6s, opacity 1s;
}

.icon-button-ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.info-panel{
  &__icon {
    background-size: 16px;
    font-size: 20px;
  }
  &__button{
    min-width: 28px;
    width: max-content;
    height: 28px;
    padding: 0 !important;
    border-radius: 4px !important;
    &:hover{
      background-color: rgba(232,232,232,1);
    }
  }
}

.input-icon {
  &__icon {
    background-size: 16px;
    font-size: 16px;
  }
  &__button{
    min-width: 28px;
    width: max-content;
    height: 28px;
    padding: 0 !important;
  }
}
