@use "../../../../css/style-variables" as *;

$arrow-icon-size: 9px;
$icon-size: 11px;

.sorting-title {
  display: flex;
  cursor: pointer;
  user-select: none;
  overflow: visible !important;

  &__title {
    display: flex;
    position: relative;
    overflow: visible !important;
  }

  &__icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: 4px;
  }

  &__icon {
    color: $design-gray-90;
    &:hover{
      color: $design-blue;
    }
  }

  &__icon-asc {
    color: $design-gray-90;
    transform: rotate(180deg);
    &:hover{
      color: $design-blue;
    }

  }

  &__icon-desc {
    color: $design-gray-90;
    &:hover{
      color: $design-blue;
    }
  }
}
