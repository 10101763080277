.loading-row {
  background-color: #F5F5F5 !important;
  display: table-row;
  position: relative;
  height: 0;
  border-right: 1px solid #F5F5F5 !important; //скрываем бордер таблицы, такой вот костыль

  &__search {
    height: 60px !important;
    background: #FFFFFF !important;
    .loading-row__container {
      top: 0 !important;
    }
  }

  &__cell {
    display: none;
    position: sticky;
    left: 50%;
    padding: 0;
  }

  &__empty-cell {
    padding: 0;
  }

  &__container {
    position: absolute;
    top: 40vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }
}
