@use "../../../css/style-variables" as *;
$color: $design-black;

.MuiTooltip-tooltip.custom-tooltip {
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $color !important;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0;
  white-space: pre-wrap;
  max-width: 240px;
  max-height: calc(100vh - 50px);
  &.empty-tooltip {
    display: none;
  }
  .tooltip-entry {
    overflow: hidden;
    max-height: calc(100vh - 54px);
  }
}

.MuiTooltip-arrow.custom-tooltip-arrow {
  border-width: 4px;
  border-style: solid;
  width: auto !important;
  height: auto !important;

  &:before {
    content: none;
  }
}

.MuiTooltip-popperArrow {
  &[data-popper-placement*="left"] {
    &.MuiTooltip-popper {
      margin-right: 8px;
    }
    .MuiTooltip-arrow {
      border-color: transparent transparent transparent $color;
      margin-right: -8px !important;
    }
  }

  &[data-popper-placement*="right"] {
    &.MuiTooltip-popper {
      margin-left: 8px;
    }
    .MuiTooltip-arrow {
      border-color: transparent $color transparent transparent;
      margin-left: -8px !important;
    }
  }

  &[data-popper-placement*="bottom"] {
    &.MuiTooltip-popper {
      margin-top: 8px;
    }
    .MuiTooltip-arrow {
      border-color: transparent transparent $color;
      margin-top: -8px !important;
    }
  }

  &[data-popper-placement*="top"] {
    &.MuiTooltip-popper {
      margin-bottom: 8px;
    }
    .MuiTooltip-arrow {
      border-color: $color transparent transparent;
      margin-bottom: -8px !important;
    }
  }
}