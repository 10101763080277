.side-menu {
  z-index: 1400;
  height: 100%;

  &__burger {
    background-color: unset !important;
    padding: 14px 16px !important;
    box-shadow: none !important;
    color: #FFFFFF;
    &:hover{
      color: #BDBDBD;
    }
  }

  &__drawer {
    flex: 0 0 auto;
    visibility: visible !important;
    z-index: 6;

    > div {
      background-color: transparent;
    }
  }

  &__paper {
    position: fixed;
    top: 0;
    width: 235px;
    height: 100%;
    background-color: #424242 !important;
    box-shadow: none;

    &-open {
      transform: translate(0px, 0px);
      transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &-close {
      transform: translateX(-259px);
      transition: transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    &:focus {
      outline: none;
    }
  }

  &__list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:focus {
      outline: none;
    }

    nav {
      padding: 0 !important;
    }
  }

  &__close {
    color: white !important;
    padding: 14px 16px !important;
    height: 48px;
    width: 52px !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    box-shadow: none !important;
    &:hover {
      color: #BDBDBD !important;
    }
  }
}

