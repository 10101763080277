.reject-success-form{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 132px;
  .kitty-image{
    //background-image: url("../../../../../css/img/svg/kitty_thanks.svg");
    background-image: url("../../../css/img/svg/kitty_thanks.svg");
    transform: scale(1);
    width: 100px;
    height: 100px;
  }
  .text-block{
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;

    &-title{
      color: #0AB14D;
      font-size: 24px;
      line-height: normal;
      letter-spacing: -0.2px;
      margin-bottom: 4px;
    }
    &-description{
      color: #212121;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.213px;
    }
  }
}