@use "../../../../../components/Table/css/common/table-variables.scss" as *;

.list {
  width: 100%;
  height: 100%;

  nav {
    padding-top: 0;
  }

  .item {
    color: #FFFFFF !important;
    padding-right: 16px;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
    justify-content: flex-start;
    text-decoration: none;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    outline: none;
    border: 0;
    margin: 0;
    cursor: pointer;

    &__text {
      display: flex;
      align-items: center;
      height: 24px;
      flex: 1 1 auto;
      padding: 0 16px;
      min-width: 0;
      padding-left: 0 !important;

      &-primary {
        color: white;
        font-size: 16px;
        font-weight: 100;
        font-family: $font-family;
        //line-height: 1.5em;
        text-align: left;
        text-decoration: none;
      }
    }

    &__icon {
      color: white;
    }

    &__label-icon {
      padding: 0px 5px 5px 5px;
    }

    &:hover {
      //background-color: #555555;
    }

    &:focus {
      background-color: #555555;
    }

    .nested-list {
      padding-left: 35px;
    }
  }

  .nested-item {

  }

  .highlighted-item {
    background-color: #555555 !important;

  }
  .parent-item.highlighted-item {
    border-top: 1px solid #555555 !important;
  }
  .parent-item {
    height: 59px;
    border-top: 1px solid rgba(97, 97, 97, 0.4);
  }
  .selected-item {
    background-color: #717171 !important;
  }

  .collapse {

  }
}

.icon-flipped {
  transform: scaleX(-1) !important;
}