$red: #EA3425;
$green: #0AB14D;

.kitty-notify{
  display: flex;
  width: 494px;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  color: #212121;
  font-family: Source Sans Pro;
  border-radius: 6px;
  border: 1px solid #D6D6D6;
  border-left: 4px solid $red;

  .kitty-image{
    width: 100px;
    height: 100px;
    background-image: url("../../../../../css/img/svg/kitty_wrong_pin.svg");
    transform: scale(-1, 1);
  }

  .title{
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $red;
    margin: 0 0 4px;
  }

  .description{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.213px;
  }

  &.valid{
    border-left: 4px solid $green;

    .kitty-image{
      background-image: url("../../../../../css/img/svg/kitty_thanks.svg");
      transform: scale(1);
    }

    .title{
      color:$green;
    }
  }

}