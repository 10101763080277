@use "../../../css/style-variables" as *;

.scrollable {
  display: flex;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    width: 100%;
    //height: 100%;
  }

  &__menu-container {
    display: inline-table;
    padding: 48px 0 0 16px;
  }

  &__menu {
    width: 280px;
    border: 1px solid #E0E0E0;

    position: sticky;
    top: 48px;
  }

  &__menu-item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 12px;
    width: 100%;
    height: 47px;
    background-color: $design-white;

    cursor: pointer;
    user-select: none;

    color: $design-black;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;

    &:not(:last-child) {
      border-bottom: 1px solid #E0E0E0;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    &-adornment {
      color: $design-gray-80;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
    }

    &-selected {
      color: $design-blue !important;
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        top: 0;
        left: 0;
        bottom: 0;
        margin-right: -1px;
        background-color: $design-blue;

      }
    }
  }

  &__scrollbar {
    display: grid !important;
  }
}
