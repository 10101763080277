.custom-table {
  &__container {
  }

  &__columns {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  &__column {
    display: flex;
    justify-content: center;
  }

  &__rows {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: stretch;
    width: 100%;
    min-height: 32px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
