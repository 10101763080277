@use "../../../components/Table/css/common/table-variables.scss" as *;

.filter-autocomplete-form {
  width: 100%;
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.filter-autocomplete-menu {
  border-radius: 3px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.200994);
  background-color: #fff;
  //padding: 8px 0;
  margin-top: 4px;
  width: 150px;
  //font-size: 90%;
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 60%;
  z-index: 1101;

  .filter-autocomplete-items {
    overflow: hidden;
    background-color: #fff;
    //padding: 8px 0;
    font-size: 90%;
    min-width: 140px;
    //max-height: 550px;
  }

  .filter-autocomplete-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    border-top: 1px solid #D6D6D6;

    .filter-autocomplete-button {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-transform: none;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 28px;
      min-width: 28px;
      margin: 3px 0;
      width: 66px;
    }

    .cancel_button {
      margin-left: 10px;
    }
  }

  .hovered, .selected {
    background-color: #f5f5f5 !important;
  }

  .filter-autocomplete-item {
    height: 32px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    cursor: pointer;

    /*&:hover {
      background-color: #f4f4f4 !important;
    }*/

    .filter-autocomplete-item-select {
      padding-left: 12px;
    }
    .filter-autocomplete-item-label-container {
      min-width: 0;
    }
    .filter-autocomplete-item-label {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: $font-family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #424242;
      padding-left: 12px;
      padding-right: 12px;
      align-self: center;

      .description-wrapper{
        display: flex;

        .key{
          width: 88px;
          margin-right: 24px;
        }

        .description{
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.filter-autocomplete-input {
  //padding-right: 15px;
  .filter-autocomplete-close-button {
    &_icon {
       font-size: 14px !important;
       padding: 0px !important;
       border-radius: 50%;
     }
    > button {
      position: relative;
      right: 0;
      padding: 0 !important;
      min-width: 25px;
      border-radius: 50%;
      min-height: 25px;
    }
  }

  .filter-autocomplete-button {
    position: relative;
    right: 0;
    padding: 0 !important;
    width: 25px !important;
    height: 25px !important;
  }

  .filter-autocomplete-button-icon {
    font-size: 14px;
    padding: 0;
  }
}

.filter-autocomplete-input-label {
  font-size: 14px !important;
  line-height: 16px !important;
}

.filter-autocomplete-arrow {
  position: absolute;
  right: 10px;
  padding: 0 !important;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54) !important;
}

.filter-autocomplete {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;
}

.arrow-drop-down {
  position: absolute;
  right: 0;
  top: 7px;
}
.button-input {
  padding: 0px 10px !important;
  &__icon {
    font-size: 20px;
  }
}

.filter-autocomplete_button__close {
  width: 25px !important;
  height: 25px !important;

  &_flex {
    display: flex !important;
  }

  &_none {
    display: none !important;
  }
}

.green {
  color: #4CAF50 !important;
}

.green-underline:after {
  border-bottom: 2px solid #4CAF50 !important;
}

.green-underline:before {
  border-bottom: 1px solid #4CAF50 !important;
}

.green-underline:hover:not(:disabled):not(:focus):not(.alc-text-field_error):before {
  border-bottom: 2px solid #4CAF50 !important;
}

@media screen and (max-width: 768px) {
  .filter-autocomplete-menu {
    position: absolute;
    margin-top: 48px;
    top: unset !important;
  }
}

