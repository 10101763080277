.info-row{
  display: flex;
  align-items: stretch;
  margin-bottom: 4px;

  &:last-child{
    margin-bottom: 0;
  }

  .cell{
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(50, 50, 50, 1);

    &.left{
      width: 80px;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
    }
    &.right{
      font-weight: 500;
      width: 104px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      height: 16px;
    }
    &.multi-line{
      height: unset;
      display: flex;
      flex-direction: column;
      white-space: normal;
    }
    &.debts{
      color:rgba(234, 52, 37, 1);
    }
  }
}