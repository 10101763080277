@use "../../../components/Table/css/common/table-variables.scss" as *;

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 224px;
  opacity: 1;
  transition: opacity 50ms ease-in-out;
  border-left: 1px solid #D6D6D6;

  &.hidden{
    width: 0;
    opacity: 0;
  }

  .sidebar-button-hide{
    width: 7px;
    position: absolute;
    top: 0;
    z-index: 5;
    height: 100%;
    left: -1px;
    border-left: 1px solid #D6D6D6;
    cursor: pointer;

    &:hover{
      border-left: 2px solid rgba(25, 118, 210, 1);
    }
    &:active{
      border-left: 2px solid rgba(25, 118, 210, 1);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 100%;

    &_top {
      display: flex;
      flex-direction: column;
      padding: 16px 0 0;
    }

    &_bottom {
      display: block;
      padding-bottom: 48px;
    }
  }
  &__items_wrapper {
    padding: 0 16px;
  }

  &__item_wrapper {
    margin-bottom: 16px;
    &.first-input{
      padding-top: 8px;
    }
    &.button-margin{
      margin-bottom: 24px;
    }
  }

  &__button_item_wrapper{
    .button-root {
      padding: 10px 18px;
    }
  }

  &__radio_item_wrapper{
    .MuiFormGroup-root{
      gap: 12px;
    }
  }

  &__date_item_wrapper {
    .date-input-container {
      .margin-container {
        margin-top: 0;
      }
      .MuiInputBase-input {
        padding: 9px 12px 10px 12px;
      }
    }
  }

  &__checkbox-wrapper_alpha {
    margin-bottom: 12px;
  }

  &__filter-wrapper_margin_top {
    margin-top: 15px !important;
  }

  &__filter-wrapper_date_range {
    .DateInput_input {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 16px;
      color: currentColor;
      width: 100%;
      border: none;
      outline: none;
      margin: 0;
      display: block;
      min-width: 0;
      box-sizing: content-box;
      background: none;
      -webkit-tap-highlight-color: transparent;
      text-overflow: ellipsis;
      padding: 9px 2px 9px 12px;

      &::placeholder {
        color: #bebebe;
        opacity: 1;
      }
    }
  }

  &__button-wrapper_margin-bottom {
    margin-bottom: 25px !important;
  }

  &__button-wrapper_small-margin-bottom {
    margin-bottom: 5px !important;
  }

  &__input-wrapper_margin_bottom {
    margin-bottom: 0 !important;
  }

  &__element-wrapper_margin-bottom {
    margin-bottom: 10px !important;
  }

  &__buttons {
    display: block !important;
    margin-bottom: 15px;
  }


  &__button {
    background-color: #2196F3 !important;
    text-transform: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 28px !important;
    min-width: 28px !important;
    margin: 3px 0 !important;
  }

  &__button_generate_translators {
    background-color: #a7a5d6 !important;
  }

  &__button_generate_translators:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &__button_generate_quotes {
    color: #212121 !important;
    background-color: #A5D6A7 !important;
  }

  &__button_generate_customer_invoice {
    color: #212121 !important;
    background-color: #A5D6A7 !important;
  }

  &__button_generate_customer_invoice:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &__button_generate_partners {
    background-color: #90caf9 !important;
  }

  &__button-helper-text {
    width: calc(100% + 32px);
    background: #F4F4F4;
    margin-left: -16px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    .value{
      font-weight: 500;
    }
  }

  &__button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &__filters {
    display: block !important;

  }

  &__textarea {
    display: block !important;
    padding: 12px 0 0px 15px !important;
  }

  &__filter {
    margin-bottom: 15px;
  }

  &__widgets {

  }

  &__checkboxes {
    .MuiIconButton-root-40 {
      width: 38px !important;
      height: 38px !important;
      margin-left: 5px !important;
    }
  }
  &__radio-buttons {
    display: flex;
    margin-bottom: 15px;
  }
  &__radio-buttons-language {
    margin-right: 30px;
  }

  &__loading {
    text-align: center;
    margin-bottom: 0 !important;
  }

  &__loading-margin {
    text-align: center;
    margin-top: 10px;
  }
}

.disable-sidebar-scroll {
  overflow: hidden !important;
  height: 100% !important;
  margin-right: 0px !important;
}

.sidebar-button-show{
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  right: 8px;
  border-radius: 4px;
  opacity: 0.8;
  background-image: url("../../../css/img/svg/open-sidebar.svg"),linear-gradient(to bottom, rgba(245, 245, 245, 1), rgba(245, 245, 245, 1));

  &:hover{
    background-image: url("../../../css/img/svg/open-sidebar.svg"),linear-gradient(to bottom, rgba(232, 232, 232, 1), rgba(232, 232, 232, 1));
  }

  &:active{
    background-image: url("../../../css/img/svg/open-sidebar.svg"),linear-gradient(to bottom, rgb(227, 242, 253), rgb(227, 242, 253));
  }
}