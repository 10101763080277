@use "../../../../../components/Table/css/common/table-variables.scss" as *;

.form-textarea {

  &__link-container {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    border-radius: 4px;
    padding: 9px 12px;
    border: 1px solid #BDBDBD;
    position: relative;
  }
  &-label{
    font-size: 10px;
    line-height: 1.172;
    color: #BDBDBD;
    background: #fff;
    position: absolute;
    top: -7px;
  }
}