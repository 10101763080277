$duration: 1000ms;

@keyframes opacity-frame {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inheritHighlight {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes highlight {
  0% {
    background-color: rgba(33, 150, 243, 0.31);
  }
  100% {
    background-color: #fff;
  }
}

.alc-table-row-animate {
  .alc-table-td {
    transition: opacity $duration ease-out !important;
    animation: opacity-frame $duration ease-out !important;

    &.alc-fixed-column {
      transition: opacity $duration ease-out !important;
      animation: opacity-frame $duration ease-out !important;
    }
  }
}

.alc-table-cell-animate {
  animation: highlight $duration ease-out !important;
  .alc-table-flex-container {
    .alc-table-td-content {
      transition: opacity $duration ease-out !important;
      animation: inheritHighlight $duration ease-out !important;
    }
  }
}