.context__overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: fixed;
}

.context__container {
  z-index: 1300;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  border-radius: 4px;
  background-color: #fff;

  min-height: 16px;
  max-height: calc(100% - 96px);
  min-width: 16px;
  max-width: calc(100% - 32px);

  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
