@use "../../../components/Table/css/common/table-variables.scss" as table;
@use "../../../css/style-variables" as *;

.form-radio-buttons {
  &__control-root {
    display: flex !important;
  }
  &__control-label-value {
    width: 100%;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
  &__radio_button {
    padding: 0  12px 0 0 !important;
    &:hover{
      background-color: transparent !important;
      .radio-icon{
        color: $design-blue-hover;
      }
    }
    .radio-icon{
      color: $design-gray-70;
    }
    & .MuiTouchRipple-root {
      display: none;
    }
  }
  &__radio_button_checked {
    color: $design-blue !important;
  }
  &__control-label {
    margin: 0 !important;
    align-items: flex-start !important;
  }
  &__buttons-label {
    display: flex;
    font-family: table.$font-family;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: $design-gray-80;
    padding-bottom: 12px;
  }
}