.snackbar {
  > div {
    flex-wrap: nowrap !important;
    //min-width: 180px !important;
  }
}

.snackbar__button {
    padding: 12px;
    color: #FFFFFF;
    border-radius: 50%;
  }

.normal-margin-bottom {
  margin-bottom: 24px;
}

.snackbar_error {
  background-color: #EF5350 !important;
}

.snackbar_warning {
  background-color: #FF9800 !important;
}

.snackbar_success {
  background-color: #4CAF50 !important;
}

.snackbar_default {
  background-color: #212121 !important;
}

.snackbar-container {
  padding: 6px 16px !important;
  min-width: auto !important;
}