@use "common/table-variables.scss" as *;

.alc-table-head {
  .alc-table-row {

    .alc-table-th {
      position: sticky;
      top: 0;
      z-index: 4;
      background-color: $background-color;
      color: $th-color;
      vertical-align: middle;
      padding: 0;

      .alc-table-th-content {
        width: 100%;
        height: 100%;
        padding: 8px;
        display: flex;
        align-items: flex-end;

        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        border-bottom: none;
        &.number-type {
          justify-content: flex-end;
          text-align: right;
        }
        .sorting-title__icons, .refresh-container {
          height: 16px;
        }
      }
      &:first-child {
        .alc-table-th-content {
          padding: 8px 8px 8px 16px;
        }
      }

      .alc-table-th-content-align-start {
        display: flex;
        align-items: baseline;
        height: 55px;
      }

      .alc-table-th-with-description {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start !important;
      }

      .alc-table-th-description {
        font-size: 10px;
        color: rgba(0,0,0,0.61);
        font-weight: 500;
      }

      .alc-table-td-button {
        align-self: flex-end;
        background-color: transparent;
        box-shadow: none !important;
        border: 0;
        vertical-align: bottom;
        padding: 0 !important;
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;

        .alc-table-td-icon {
          color: $td-color;
          &.table-icon-open {
            color: $cell-focus-color;
          }
        }

        &.align-right {
          margin-left: auto !important;
        }

        &.align-center {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
      .alc-table-td-button:hover {
        text-decoration: none;
      }
      .alc-table-td-button:active {
        text-decoration: none;
      }

      //todo показывать кнопку при наведении
      &.hovered {
        .alc-table-hovered-button {
          //display: none !important;
          visibility: hidden !important;
        }

        &:hover {
          .alc-table-hovered-button {
            //display: inline-flex !important;
            visibility: visible !important;

            &.align-right {
              margin-left: auto !important;
            }

            &.align-center {
              margin-left: auto !important;
              margin-right: auto !important;
            }
          }
        }
      }

      &.sortable {
        cursor: pointer;

        &:hover {
          background-color: $row-focus-color;
        }

        &.sorted {
          background-color: $row-focus-color;

          .alc-table-th-content {
            padding-left: 17px !important;
          }

          &::before{
            content: "";
            display: block;
            bottom: 10px;
            left: 5px;
            position: absolute;
            width: 9px;
            height: 11px;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA5IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTIuMiAoNjcxNDUpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkM1QkQyRDc2LUFDRUItNDMxMy05QTVELTg3NDhFQjQ2QzcwNjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZyBpZD0ibml0cm8tdHJhbnNsYXRvciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlVJLUtJVC1ERU1PIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzAuMDAwMDAwLCAtMTMxMi4wMDAwMDApIiBmaWxsPSIjNDc0NzQ3Ij4KICAgICAgICAgICAgPHBhdGggZD0iTTc1LDEzMjAuOTE2NjcgTDc3LjUsMTMxOCBDNzguNSwxMzE4IDc5LDEzMTggNzksMTMxOCBDNzksMTMxOCA3Ny41LDEzMTkuNjY2NjcgNzQuNSwxMzIzIEw3MCwxMzE4IEw3MS41LDEzMTggTDc0LDEzMjAuOTE2NjcgTDc0LDEzMTIgTDc1LDEzMTIgTDc1LDEzMjAuOTE2NjcgWiIgaWQ9InNvcnRpbmctYXJyb3ctZG93biI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
          }
        }
      }

      &.border-left {
        box-shadow: inset 1px 0 #E0E0E0;
      }
      &.border-right {
        box-shadow: inset -1px 0 #E0E0E0;
      }

      &__required {
        margin-left: 3px;
        color: #f44336;
      }
    }

    .alc-table-th-tooltip {
      padding: 0;
    }
    .alc-table-th-custom-tooltip {
      font-weight: 500;
    }
  }
}
